import slugify from "slugify";

import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetProductsQuery } from "services/product";
import { fireToast } from "utils/swal";
import { usePrices } from "./usePrices";

import { SUPPORTED_PLATFORMS } from "constants/platforms";
import { roundToTwoDecimals } from "utils/numbers";

/**
 * Hook to get products
 */
const useProducts = () => {
    const { t, i18n } = useTranslation("products");
    const { data, error, isFetching } = useGetProductsQuery();
    const { getPriceById } = usePrices();

    const getProductNameById = useCallback(
        (productId) => {
            const product = data?.find((product) => product.id === productId);
            if (!product) {
                return t("product_not_found");
            }
            return t(slugify(product.name, { lower: true, replacement: "_" }));
        },
        [data, i18n.language]
    );

    const getProductDefaultPriceById = useCallback(
        (productId) => {
            const product = data?.find((product) => product.id === productId);
            if (!product) return undefined;
            const price = getPriceById(product.default_price);
            if (!price) return undefined;
            return price?.unit_amount_decimal / 100;
        },
        [data, getPriceById]
    );

    const getProductCostById = useCallback(
        (productId) => {
            const product = data?.find((product) => product.id === productId);
            if (!product) return null;
            return parseFloat(product.metadata.cost_by_minute) / 60;
        },
        [data, i18n.language]
    );

    const getProductDefaultPriceByIdInMinutes = useCallback(
        (productId) => {
            const priceBySeconds = getProductDefaultPriceById(productId);
            if (!priceBySeconds) return null;
            return roundToTwoDecimals(priceBySeconds * 60);
        },
        [getProductDefaultPriceById]
    );

    const getProductCostByIdInMinutes = useCallback(
        (productId) => {
            const costByMinute = getProductCostById(productId);
            if (!costByMinute) return null;
            return roundToTwoDecimals(costByMinute * 60);
        },
        [getProductCostById]
    );

    const calculateTotalPrice = useCallback(
        (productId, duration) => {
            const priceBySeconds = getProductDefaultPriceById(productId);
            if (!priceBySeconds) return null;
            return roundToTwoDecimals(priceBySeconds * duration);
        },
        [getProductDefaultPriceById]
    );

    const calculateTotalCost = useCallback(
        (productId, duration) => {
            const costByMinute = getProductCostById(productId);
            if (!costByMinute) return null;
            return roundToTwoDecimals(costByMinute * duration);
        },
        [getProductCostById]
    );

    useEffect(() => {
        if (error) {
            fireToast({ title: t("error.title"), text: t("error.message"), icon: "error" });
        }
    }, [error]);

    const vocalityProducts = useMemo(() => {
        return data?.filter(
            (product) => product.metadata.platform === SUPPORTED_PLATFORMS.VOCALITY
        );
    }, [data]);

    return {
        products: data,
        vocalityProducts,
        isFetching,
        getProductNameById,
        getProductCostById,
        getProductCostByIdInMinutes,
        getProductDefaultPriceById,
        getProductDefaultPriceByIdInMinutes,
        calculateTotalPrice,
        calculateTotalCost,
    };
};

export { useProducts };
