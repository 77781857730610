/**
 * File containing the pronunciation dictionary for the MURF API.
 */
const MURF_PRONUNCIATION_DICTIONARY = "vocality_pronunciation_dictionary_v2.xlsx";

/**
 * File containing the pronunciation dictionary for the Vocality API.
 */
const VOCALITY_PRONUNCIATION_DICTIONARY = "vocality_pronunciation_dictionary_v1.xlsx";

export { MURF_PRONUNCIATION_DICTIONARY, VOCALITY_PRONUNCIATION_DICTIONARY };
