import axios from "axios";

// Axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 3000;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

/**
 * Basic API client
 * @param {Object} options - Axios options
 * @returns {AxiosInstance} Axios instance
 */
export const createBaseAxiosInstance = (options) => axios.create(options);

/**
 * API client with authentication. Sends the token in the headers.
 * @param {Object} options - Axios options
 * @returns {AxiosInstance} Axios instance
 */
export const createAuthenticatedAxiosInstance = (options) => {
    const axiosClient = axios.create(options);
    axiosClient.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("token");
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    return axiosClient;
};
