import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

/**
 * VoiceSelector component for the VoiceGenerationDashboard page.
 */
const VoiceStyleSelector = () => {
    const { t, i18n } = useTranslation("generate_voice_dashboard");

    const { control, watch } = useFormContext();
    const { field } = useController({ name: "style", control });
    const voice = watch("voice");

    const handleStyleChange = (selectedOption) => {
        field.onChange(selectedOption);
    };

    const availableStyleOptions = useMemo(() => {
        if (!voice) return [];
        if (voice.metadata.availableStyles.length === 1) {
            field.onChange({
                value: voice.metadata.availableStyles[0],
                label: t(`murfStyle.${voice.metadata.availableStyles[0]}`),
            });
        }
        return voice.metadata.availableStyles.map((style) => ({
            value: style,
            label: t(`murfStyle.${style}`),
        }));
    }, [voice, i18n.language]);

    return (
        <SoftBox>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("style_options_input_label")}
            </SoftTypography>
            <SoftSelect
                placeholder={t("style_options_input_placeholder")}
                value={availableStyleOptions.length === 1 ? availableStyleOptions[0] : field.value}
                onChange={handleStyleChange}
                options={availableStyleOptions}
                ref={field.ref}
                isDisabled={availableStyleOptions.length === 1}
            />
        </SoftBox>
    );
};

export { VoiceStyleSelector };
