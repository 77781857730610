/**
 * @description This object contains all the possible roles
 */
const ROLES = {
    ADMIN: "ADMIN",
    PRODUCTOR: "PRODUCTOR",
    TECHNICIAN: "TECHNICIAN",
    SPEAKER: "SPEAKER",
    SUPERUSER: "SUPERUSER",
    CLIENT: "CLIENT",
};

const ROLESIDS = {
    ADMIN: 1,
    PRODUCTOR: 2,
    TECHNICIAN: 3,
    SPEAKER: 4,
    CLIENT: 5,
};

export { ROLES, ROLESIDS };
