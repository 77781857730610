const voiceSampleMappings = {
    "en-US-edmund": "https://murf.ai/public-assets/voice/Edmund1.mp3",
    "en-US-ken": "https://murf.ai/public-assets/voice/Ken2.mp3",
    "en-US-michelle": "https://murf.ai/public-assets/voice/Michelle1.mp3",
    "en-US-naomi": "https://murf.ai/public-assets/voice/Naomi1.mp3",
    "en-US-wayne": "https://murf.ai/public-assets/voice/Wayne1.mp3",
    "ta-IN-mani": "https://murf.ai/public-assets/voice/Suresh.mp3",
};

/**
 * Get the audio sample URL for the given VoiceId
 * @param {*} VoiceId - The VoiceId of the audio sample
 * @returns {string} The audio sample URL
 */
const getAudioSampleUrlFromMurf = (VoiceId) => {
    if (voiceSampleMappings[VoiceId]) {
        return voiceSampleMappings[VoiceId];
    }

    const re = /\w+-\w+-(.*)/;
    const voiceNameMatch = VoiceId.match(re);

    if (!voiceNameMatch || voiceNameMatch.length < 2) {
        return null;
    }

    const voiceName = voiceNameMatch[1];
    const formatedVoiceName =
        voiceName.charAt(0).toUpperCase() + voiceName.slice(1).replace(/é/g, "e");

    return `https://murf.ai/public-assets/voice/${formatedVoiceName}.mp3`;
};

/**
 * Format the audio file name for murf audio files
 * @param {string} title - The title of the audio file
 * @param {string} format - The format of the audio file
 * @returns
 */
const formatAudioFileName = (title, format) => {
    return `${title.replace(/ /g, "-")}.${format.toLowerCase()}`;
};

export { getAudioSampleUrlFromMurf, formatAudioFileName };
