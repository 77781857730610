import PropTypes from "prop-types";

// Soft UI theme components
import SoftBox from "components/SoftBox";

// Material-UI components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import { REQUESTSTATE } from "constants/request";
import { useTranslation } from "react-i18next";

import { downloadResource } from "utils/download";

/**
 * Download icon for audio files. Available in two modes: link and fetch.
 * @param {string} audioUrl URL of the audio file to download.
 * @param {string} status Status of the request.
 * @param {string} mode Mode to download the file. Default is "link".
 */
const DownloadAudioIcon = ({ audioUrl, status, filename = "audio", mode = "fetch" }) => {
    const { t } = useTranslation("history");

    const handleClick = () => {
        if (mode === "link") {
            window.location.href = audioUrl;
        } else if (mode === "fetch") {
            downloadResource(audioUrl, filename);
        } else {
            throw new Error("Invalid mode");
        }
    };

    return (
        <SoftBox ml={-1.325}>
            <Tooltip
                title={status == REQUESTSTATE.COMPLETED && audioUrl ? t("download") : ""}
                placement="top"
            >
                <IconButton
                    size="medium"
                    disabled={status == REQUESTSTATE.COMPLETED && audioUrl ? false : true}
                    onClick={handleClick}
                >
                    <Icon
                        color={
                            status == REQUESTSTATE.COMPLETED && audioUrl ? "secondary" : "disabled"
                        }
                    >
                        download
                    </Icon>
                </IconButton>
            </Tooltip>
        </SoftBox>
    );
};

DownloadAudioIcon.propTypes = {
    audioUrl: PropTypes.string,
    status: PropTypes.string,
    filename: PropTypes.string,
    mode: PropTypes.string,
};

export { DownloadAudioIcon };
