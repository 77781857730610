import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Card, CircularProgress, Divider, Grid } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import { TextInput } from "components/Forms/TextInput";

import { LanguageSelector } from "components/LanguageSelector";
import { TermsOfService } from "./TermsOfService";

import { userCreationSchema, userCreationDefaultValues } from "./schema";
import { Link } from "react-router-dom";
import { useRegister } from "hooks/useRegister";
import { useEffect } from "react";
import { useInvitationToken } from "hooks/useInvitationToken";

const RegisterFormTitle = () => {
    const { t } = useTranslation("register");
    return (
        <SoftBox display="flex" alignItems="center">
            <SoftBox lineHeight={0}>
                <SoftTypography variant="h4" fontWeight="bold">
                    {t("title")}
                </SoftTypography>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                    {t("subtitle")}
                </SoftTypography>
            </SoftBox>
            <SoftBox ml="auto">
                <LanguageSelector />
            </SoftBox>
        </SoftBox>
    );
};

/**
 * Register form
 */
const RegisterForm = () => {
    const { t } = useTranslation("register");
    const { token, isValid: tokenIsValid, data: invitationData } = useInvitationToken();

    const { registerUser, isSuccess, isLoading } = useRegister();

    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(userCreationSchema),
        defaultValues: userCreationDefaultValues,
    });

    const onSubmit = (data) => {
        registerUser({ ...data, invitation_token: token });
    };

    useEffect(() => {
        if (tokenIsValid) {
            methods.setValue("email", invitationData.email);
        }
    }, [tokenIsValid]);

    return (
        <FormProvider {...methods}>
            <Card sx={{ p: 3 }}>
                {/* Register form title */}
                <RegisterFormTitle />
                {/* Form inputs */}
                <Grid
                    container
                    spacing={2}
                    sx={{
                        padding: 2,
                        justifyContent: "center",
                    }}
                >
                    {/* Name and surname */}
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            name="name"
                            label={t("form.fields.name.label")}
                            placeholder={t("form.fields.name.placeholder")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            name="surname"
                            label={t("form.fields.surname.label")}
                            placeholder={t("form.fields.surname.placeholder")}
                        />
                    </Grid>
                    {/* Email */}
                    <Grid item xs={12}>
                        <TextInput
                            name="email"
                            disabled={tokenIsValid}
                            label={t("form.fields.email.label")}
                            placeholder={t("form.fields.email.placeholder")}
                        />
                    </Grid>
                    {/* Password */}
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            name="password"
                            label={t("form.fields.password.label")}
                            placeholder={t("form.fields.password.placeholder")}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            name="repeatPassword"
                            label={t("form.fields.repeat_password.label")}
                            placeholder={t("form.fields.repeat_password.placeholder")}
                            type="password"
                        />
                    </Grid>
                </Grid>
                {/* Terms and conditions */}
                <Divider />
                <TermsOfService />
                {/* Submit button */}
                <Divider />
                <Grid container>
                    <Grid item xs={12}>
                        {isLoading ? (
                            <SoftBox sx={{ flex: 1 }} display="flex" justifyContent="center">
                                <CircularProgress />
                            </SoftBox>
                        ) : (
                            <SoftButton
                                variant="gradient"
                                color="secondary"
                                type="submit"
                                fullWidth
                                onClick={methods.handleSubmit(onSubmit)}
                            >
                                {t("form.submit_button")}
                            </SoftButton>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <SoftTypography variant="caption" color="text">
                            {t("already_have_account")}
                            <Link to="/authentication/sign-in"> {t("sign_in_link")}</Link>
                        </SoftTypography>
                    </Grid>
                </Grid>
            </Card>
        </FormProvider>
    );
};
export { RegisterForm };
