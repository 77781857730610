import Proptypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

/**
 * Profile information item component.
 * @param {string} label - The label for the information item.
 * @param {string} value - The value for the information item.
 */
const UserProfileInfoItem = ({ label, value, ...rest }) => {
    return (
        <SoftBox {...rest}>
            <SoftTypography variant="caption">{label}</SoftTypography>
            <SoftTypography variant="body2">{value}</SoftTypography>
        </SoftBox>
    );
};

UserProfileInfoItem.propTypes = {
    label: Proptypes.string.isRequired,
    value: Proptypes.string.isRequired,
};

export { UserProfileInfoItem };
