/**
 * Creates a link element to download a resource from a URL.
 * @param {string} resourceURL URL of the resource to download.
 * @param {string} format Format of the resource to download.
 */
const downloadResource = async (resourceURL, filename) => {
    const response = await fetch(resourceURL);

    if (!response.ok) throw new Error("Error al descargar el archivo");

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
};

export { downloadResource };
