/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "App";
import { store } from "./app/store";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";
import { ConfigProvider } from "layouts/dashboards/context/ConfigContext";

// Localization
import "./locales/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <SoftUIControllerProvider>
                <ConfigProvider>
                    <App />
                </ConfigProvider>
            </SoftUIControllerProvider>
        </BrowserRouter>
    </Provider>
);
