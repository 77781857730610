/**
 * Array of audio formats that are supported by the Murf API.
 */
const AUDIO_FORMATS_OPTIONS = ["MP3", "WAV", "FLAC", "ALAW", "ULAW"];

/**
 * Array of all the possible frequency options for the Murf API.
 */
const FRECUENCY_OPTIONS = ["8000", "24000", "44100", "48000"];

/**
 * Array of all the possible channel type options for the Murf API.
 */
const CHANNEL_TYPE_OPTIONS = ["MONO", "STEREO"];

/**
 * Array of all the possible model version options for the Murf API.
 */
const MODEL_VERSION_OPTIONS = ["GEN1", "GEN2"];

export { AUDIO_FORMATS_OPTIONS, MODEL_VERSION_OPTIONS, FRECUENCY_OPTIONS, CHANNEL_TYPE_OPTIONS };
