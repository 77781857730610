import { useCallback, useEffect, useState } from "react";
import {
    useCreateSynthesisRequestMutation,
    useGetSynthesisRequestBySynthesisRequestIdQuery,
} from "services/inference";

/**
 * Hook for generating vocality audio
 */
const useGenerateMurfAudio = () => {
    const [isPollingActive, setIsPollingActive] = useState(false);

    const [
        requestAudioSynthesis,
        {
            data: synthesisRequestData,
            error: synthesisRequestError,
            isSuccess: synthesisRequestIsSuccess,
            reset: resetSynthesisRequest,
        },
    ] = useCreateSynthesisRequestMutation();

    const {
        data: synthesisQueryData,
        isSuccess: synthesisQueryIsSuccess,
        error: synthesisQueryError,
    } = useGetSynthesisRequestBySynthesisRequestIdQuery(synthesisRequestData?.request_id, {
        pollingInterval: isPollingActive ? 3000 : 0,
        skip: !isPollingActive,
    });

    useEffect(() => {
        if (synthesisRequestIsSuccess && synthesisRequestData?.request_id) {
            setIsPollingActive(true);
        }
    }, [synthesisRequestIsSuccess, synthesisRequestData]);

    useEffect(() => {
        if (
            (synthesisQueryIsSuccess && synthesisQueryData?.status !== "IN_PROGRESS") ||
            synthesisQueryError
        ) {
            setIsPollingActive(false);
        }
    }, [synthesisRequestIsSuccess, synthesisQueryData]);

    const startAudioSynthesisToMurf = useCallback((data) => {
        const {
            title,
            processedText,
            voice,
            style,
            audioFormat,
            channelType,
            language,
            modelVersion,
            pitch,
            speed,
            frequency,
        } = data;

        requestAudioSynthesis({
            title: title,
            text: processedText,
            style: style.value,
            voice_id: voice.value,
            channel_type: channelType,
            format: audioFormat.value,
            model_version: modelVersion,
            multi_native_locale: language.value,
            pitch: pitch,
            rate: speed,
            sample_rate: frequency,

            // TODO: Add the rest of the parameters
            variation: 1,
            audio_duration: 0,
            encode_as_base64: false,
        });
    });

    return {
        startAudioSynthesisToMurf,
        audioURL: synthesisRequestIsSuccess && synthesisQueryData?.audio_url,
        isPollingActive,
        resetSynthesisRequest,

        error: synthesisQueryData?.status === "FAILED",
        synthesisRequestError,
        synthesisQueryError,
    };
};

export { useGenerateMurfAudio };
