import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

import InfoButton from "components/InfoButton";
import { TextFileUploader } from "../TextFileUploader";

/**
 *
 * @returns
 */
const MurfAudioTextEditor = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();

    const { field } = useController({
        name: "processedText",
        control,
    });

    const handleTextFileUpload = ({ content }) => {
        field.onChange(content);
    };

    return (
        <SoftBox mb={2}>
            <SoftBox mb={2}>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("text_to_generate")}
                    <InfoButton modal text={t("text_example")} />
                </SoftTypography>
                <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                    {t("text_file_upload_label")}
                </SoftTypography>
            </SoftBox>
            <TextFileUploader handler={handleTextFileUpload} />
            <SoftBox mb={2} mt={2}>
                <SoftInput
                    id="clone-text"
                    placeholder={t("processed_text_placeholder")}
                    multiline
                    value={field.value}
                    onChange={field.onChange}
                    rows={6}
                />
            </SoftBox>
        </SoftBox>
    );
};

export { MurfAudioTextEditor };
