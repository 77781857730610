import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import { useDecodeInvitationTokenQuery } from "services/auth";

const useInvitationToken = () => {
    const { t } = useTranslation("register");

    const [searchParams] = useSearchParams();
    const token = useMemo(() => {
        return searchParams.get("token");
    }, [searchParams]);

    const { isSuccess, data, error } = useDecodeInvitationTokenQuery(token, {
        refetchOnMountOrArgChange: true,
        skip: !token,
    });

    useEffect(() => {
        if (isSuccess) {
            Swal.mixin({
                toast: true,
                position: "top-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            }).fire({
                title: t("invitation_token.success.title"),
                text: t("invitation_token.success.message"),
                icon: "success",
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            Swal.mixin({
                toast: true,
                position: "top-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            }).fire({
                title: t("invitation_token.error.title"),
                text: t("invitation_token.error.message"),
                icon: "error",
            });
        }
    }, [error]);

    return {
        token,
        data,
        isValid: isSuccess,
    };
};

export { useInvitationToken };
