import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { fireToast } from "utils/swal";
import { useGetPricesQuery } from "services/stripe";

/**
 * Hook to get prices
 */
const usePrices = () => {
    const { t } = useTranslation("prices");
    const { data, error, isFetching } = useGetPricesQuery();

    const getPriceById = useCallback(
        (priceId) => {
            return data?.find((price) => price.id === priceId);
        },
        [data]
    );

    useEffect(() => {
        if (error) {
            fireToast({ title: t("error.title"), text: t("error.message"), icon: "error" });
        }
    }, [error]);

    return {
        prices: data,
        isFetching,
        getPriceById,
    };
};

export { usePrices };
