/**
 * Auth hook for login and logout
 */

import { useDispatch } from "react-redux";

import { logout } from "../features/auth/authSlice";
import { authenticate } from "../features/auth/thunks";

/**
 * Auth hook
 * @returns {Object} Auth utilities
 */
export const useLogin = () => {
    const dispatch = useDispatch();

    /**
     *
     * @param {*} credentials
     */
    const loginUser = (credentials) => {
        dispatch(authenticate(credentials));
    };

    const logoutUser = () => {
        localStorage.setItem("last_visited", null);
        dispatch(logout());
    };

    return {
        loginUser,
        logoutUser,
    };
};
