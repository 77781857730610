import { useTranslation } from "react-i18next";

import SoftTypography from "components/SoftTypography";

import { BasicDashboardLayout } from "layouts/dashboards/Basic";
import { InferenceRequestsCharts } from "components/History/Charts/InferenceRequestCharts";
import { SpeakerInferenceBillingTable } from "components/History/Tables/SpeakerInferenceBillingTable";
import { InferenceCostHistoryChart } from "components/History/Charts/InferenceCostHistoryChart";

/**
 * Page to display the history of inference requests
 */
const SpeakerBillingHistoryPage = () => {
    const { t } = useTranslation("history");

    return (
        <BasicDashboardLayout>
            {/* Title */}
            <SoftTypography variant="h4" fontWeight="medium">
                {t("speaker_billing_history_page_title")}
            </SoftTypography>
            {/* Charts */}
            <InferenceRequestsCharts
                title={t("charts.expense_history_title")}
                SecondaryChart={InferenceCostHistoryChart}
            />
            {/* Table */}
            <SoftTypography variant="h5" fontWeight="medium" mt={4}>
                {t("client_billing_table_title")}
            </SoftTypography>
            <SpeakerInferenceBillingTable />
        </BasicDashboardLayout>
    );
};

export { SpeakerBillingHistoryPage };
