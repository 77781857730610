import PropTypes from "prop-types";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { AudioGenerationSubmitButton as MurfAudioGenerationSubmitButton } from "./murf/AudioGenerationSubmitButton";
import { AudioGenerationSubmitButton as VocalityAudioGenerationSubmitButton } from "./vocality/AudioGenerationSubmitButton";
import { SUPPORTED_PLATFORMS } from "constants/platforms";

const audioGenerationSubmitButtonPlatformComponents = {
    [SUPPORTED_PLATFORMS.VOCALITY]: VocalityAudioGenerationSubmitButton,
    [SUPPORTED_PLATFORMS.MURF]: MurfAudioGenerationSubmitButton,
};

const AudioGenerationSubmitButton = ({ handleSubmit, onErrors }) => {
    const { watch } = useFormContext();

    const voice = watch("voice");

    const AudioGenerationSubmitButtonComponent = useMemo(() => {
        return (
            audioGenerationSubmitButtonPlatformComponents[voice?.metadata?.platform] ||
            VocalityAudioGenerationSubmitButton
        );
    }, [voice]);

    return <AudioGenerationSubmitButtonComponent handleSubmit={handleSubmit} onErrors={onErrors} />;
};

AudioGenerationSubmitButton.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onErrors: PropTypes.func.isRequired,
};

export { AudioGenerationSubmitButton };
