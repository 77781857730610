import { api } from "../app/baseQuery";

const subscriptionApi = api.injectEndpoints({
    tagTypes: ["Subscription"],
    endpoints: (build) => ({
        /**
         * Get subscriptions - Devuelve la suscripción del usuario
         */
        getSubscription: build.query({
            query: () => "stripe/subscriptions/read",
        }),
        /**
         * Get customer - Devuelve el cliente de stripe del usuario
         */
        getCustomer: build.query({
            query: () => "stripe/customers/read",
        }),
        /**
         * Get prices - Devuelve los precios de stripe
         */
        getPrices: build.query({
            query: () => "stripe/prices/read",
        }),
    }),
    overrideExisting: false,
});

export const { useGetSubscriptionQuery, useGetCustomerQuery, useGetPricesQuery } = subscriptionApi;
