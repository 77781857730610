import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";

import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";

import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import { ROLESIDS } from "constants/roles";
import { useSendUserInvitation } from "hooks/useSendUserInvitation";
import { defaultValues, schema } from "./schema";

import { TextInput } from "components/Forms/TextInput";
import { useCallback } from "react";

/**
 * Dialog to invite a user to the platform
 */
const UserInvitationDialog = ({ open, onClose, options }) => {
    const { t } = useTranslation(["user_invitation_dialog", "roles"]);
    const { role, voice, language } = options;

    const methods = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues: { ...defaultValues, group_id: ROLESIDS[role], voice, language },
    });

    const onSuccess = useCallback(() => {
        methods.reset();
        onClose();
    }, []);

    const { sendInvitation, isLoading } = useSendUserInvitation({ onSuccess });

    const onSubmit = (data) => {
        sendInvitation(data);
    };

    console.log({ errors: methods.formState.errors });

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                {t("title")} - {t(role.toLowerCase(), { ns: "roles" })}
            </DialogTitle>
            <DialogContent>
                <SoftBox sx={{ p: 1, display: "flex", flexDirection: "column", gap: 2 }}>
                    <DialogContentText>{t("subtitle")}</DialogContentText>
                    <FormProvider {...methods}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    name="email"
                                    label={t("email_label")}
                                    placeholder={t("email_placeholder")}
                                />
                            </Grid>
                            {isLoading ? (
                                <SoftBox sx={{ flex: 1 }} display="flex" justifyContent="center">
                                    <CircularProgress />
                                </SoftBox>
                            ) : (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <SoftButton
                                            autoFocus
                                            color="secondary"
                                            onClick={onClose}
                                            fullWidth
                                        >
                                            {t("cancel_button")}
                                        </SoftButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SoftButton
                                            type="submit"
                                            color="primary"
                                            autoFocus
                                            fullWidth
                                            onClick={methods.handleSubmit(onSubmit)}
                                        >
                                            {t("send_button")}
                                        </SoftButton>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </FormProvider>
                </SoftBox>
                {/* <Formik
                    initialValues={initialSpeakerProfileValues}
                    validationSchema={speakerProfileValidations}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form id={formID} autoComplete="off">
                            <SoftBox>
                                <SpeakerProfileInputs
                                    formFields={formFields}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                />
                                <SoftBox
                                    mt={2}
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <SoftButton autoFocus color="secondary" onClick={handleCancel}>
                                        Cancelar
                                    </SoftButton>
                                    <SoftButton type="submit" color="primary" autoFocus>
                                        Enviar correo
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </Form>
                    )}
                </Formik> */}
            </DialogContent>
        </Dialog>
    );
};

UserInvitationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    options: PropTypes.string.isRequired,
};

export { UserInvitationDialog };
