import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";

import SoftTypography from "components/SoftTypography";

import { FRECUENCY_OPTIONS as MURF_FRECUENCY_OPTIONS } from "constants/murf";

const FrequencyRadioButtons = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({
        name: "frequency",
        control,
        defaultValue: MURF_FRECUENCY_OPTIONS[0],
    });

    return (
        <SoftBox mb={2}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("frequency_options_input_label")}
            </SoftTypography>
            <SoftBox ml={2}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="frequency-row-radio-buttons-group-label"
                        value={field.value}
                        onChange={field.onChange}
                        name="row-radio-buttons-group"
                    >
                        <Stack direction="row" sx={{ gap: "3rem" }}>
                            {MURF_FRECUENCY_OPTIONS.map((freq) => (
                                <FormControlLabel
                                    key={freq}
                                    value={freq}
                                    control={<Radio sx={{ marginRight: "0.25rem" }} />}
                                    label={freq}
                                />
                            ))}
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </SoftBox>
        </SoftBox>
    );
};

export { FrequencyRadioButtons };
