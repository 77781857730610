/**
 * Groups an iterable by a key function.
 * @param {*} iterable The iterable to group.
 * @param {*} keyFn The function to get the key to group by.
 * @returns
 */
const groupBy = (iterable, keyFn) => {
    const groups = {};
    for (const item of iterable) {
        const key = keyFn(item);
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
    }
    return groups;
};

export { groupBy };
