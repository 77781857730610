/**
 * This is the main dashboard page for the voice generation feature.
 */

import { BasicDashboardLayout } from "layouts/dashboards/Basic";
import { VoiceGenerationForm } from "components/dashboard/VoiceGenerationForm";

/**
 * Dashboard page for the voice generation feature.
 */
function VoiceGenerationDashboardPage() {
    return (
        <BasicDashboardLayout>
            <VoiceGenerationForm />
        </BasicDashboardLayout>
    );
}

export { VoiceGenerationDashboardPage };
