import VocalitySlider from "components/Forms/VocalitySlider";
import InfoButton from "components/InfoButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * Speed slider component for adjusting the speed of the audio.
 */
const AudioSpeedSlider = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({ name: "speed", control, defaultValue: 1.0 });

    return (
        <SoftBox mb={2}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("speed_options_input_label")}
                <InfoButton text={t("speed_options_info_button_text")} />
            </SoftTypography>
            <VocalitySlider
                min={0.5}
                max={2}
                step={0.05}
                marks
                value={field.value}
                onChange={field.onChange}
            />
        </SoftBox>
    );
};

export { AudioSpeedSlider };
