import * as yup from "yup";

const defaultValues = {
    email: "",
};

const schema = yup.object().shape({
    email: yup.string().email("invalid_email").required("required"),
});

export { schema, defaultValues };
