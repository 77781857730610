import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { MODEL_VERSION_OPTIONS } from "constants/murf";
import InfoButton from "components/InfoButton";

/**
 * Radio buttons for selecting the model version.
 */
const ModelVersionRadioButtons = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({
        name: "modelVersion",
        control,
        defaultValue: MODEL_VERSION_OPTIONS[1],
    });

    return (
        <SoftBox mb={2}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("model_version_options_input_label")}
                <InfoButton text={t("model_version_options_info_button_text")} />
            </SoftTypography>
            <SoftBox ml={2}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="frecuency-row-radio-buttons-group-label"
                        value={field.value}
                        onChange={field.onChange}
                        name="row-radio-buttons-group"
                    >
                        <Stack direction="row" sx={{ gap: "3rem" }}>
                            {MODEL_VERSION_OPTIONS.map((freq) => (
                                <FormControlLabel
                                    key={freq}
                                    value={freq}
                                    control={<Radio sx={{ marginRight: "0.25rem" }} />}
                                    label={freq}
                                />
                            ))}
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </SoftBox>
        </SoftBox>
    );
};

export { ModelVersionRadioButtons };
