import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftDropzone from "components/SoftDropzone";

const TextFileUploader = ({ handler }) => {
    return (
        <SoftBox mb={2}>
            <SoftDropzone
                name="textfile"
                options={{
                    inputProps: {
                        multiple: false,
                        accept: "text/plain",
                    },
                }}
                handler={handler}
            />
        </SoftBox>
    );
};

TextFileUploader.propTypes = {
    handler: PropTypes.func.isRequired,
};

export { TextFileUploader };
