import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SoftTypography from "components/SoftTypography";

import { BasicDashboardLayout } from "layouts/dashboards/Basic";
import { InferenceRequestsCharts } from "components/History/Charts/InferenceRequestCharts";
import { InferenceIncomeHistoryChart } from "components/History/Charts/InferenceIncomeHistoryChart";
import { ClientInferenceRequestTable } from "components/History/Tables/ClientInferenceRequestTable";

import { ROLES } from "constants/roles";

/**
 * Page to display the history of inference requests
 */
const ClientInferenceRequestHistoryPage = () => {
    const { t } = useTranslation("history");
    const user = useSelector((state) => state.auth.userProfile);

    const incomeChartTitle = useMemo(() => {
        if (user.is_superuser || user.group === ROLES.SPEAKER) {
            return t("charts.income_history_title");
        }
        return t("charts.expense_history_title");
    }, [t, user]);

    return (
        <BasicDashboardLayout>
            {/* Title */}
            <SoftTypography variant="h4" fontWeight="medium">
                {t("inference_request_history_page_title")} - {user?.name} {user?.surname}
            </SoftTypography>
            {/* Charts */}
            <InferenceRequestsCharts
                title={incomeChartTitle}
                SecondaryChart={InferenceIncomeHistoryChart}
            />
            {/* Table */}
            <SoftTypography variant="h5" fontWeight="medium" mt={4}>
                {t("inference_request_table_title")}
            </SoftTypography>
            <ClientInferenceRequestTable />
        </BasicDashboardLayout>
    );
};

export { ClientInferenceRequestHistoryPage };
