import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

const TitleInput = () => {
    const { t } = useTranslation("generate_voice_dashboard");
    const { register } = useFormContext();
    return (
        <SoftBox>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("title_input_label")}
            </SoftTypography>
            <SoftBox>
                <SoftInput placeholder={t("title_input_placeholder")} {...register("title")} />
            </SoftBox>
        </SoftBox>
    );
};

TitleInput.propTypes = {};

export { TitleInput };
