import Swal from "sweetalert2";

/**
 * Default toast notification
 * @param {string} title - Title of the toast
 * @param {string} text - Text of the toast
 * @param {string} icon - Icon of the toast
 * @param {object} rest - Additional options for the toast
 */
const fireToast = ({ title, text, icon, ...rest }) => {
    Swal.mixin({
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        ...rest,
    }).fire({ title, text, icon });
};

export { fireToast };
