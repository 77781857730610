import PropTypes from "prop-types";

import Zoom from "@mui/material/Zoom";
import ErrorIcon from "@mui/icons-material/Error";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

/**
 * Standard error message component
 * @param {string} error - The error message to display.
 */
const ErrorMessage = ({ error }) => {
    return (
        <Zoom in={Boolean(error)}>
            <SoftBox
                borderRadius="md"
                shadow="md"
                sx={{ color: "error", display: "flex", gap: 1, alignItems: "center", padding: 3 }}
            >
                <ErrorIcon color="error" />
                <SoftTypography variant="body2">{error}</SoftTypography>
            </SoftBox>
        </Zoom>
    );
};

ErrorMessage.propTypes = {
    error: PropTypes.string,
};

export { ErrorMessage };
