import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const SoftCheckBox = ({ label, name }) => {
    const { t } = useTranslation("form");

    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = useMemo(() => errors[name], [errors[name]]);

    return (
        <SoftBox>
            <FormControlLabel control={<Checkbox {...register(name)} />} label={label} />
            {error && (
                <SoftTypography variant="caption" color="error">
                    {t(error?.message)}
                </SoftTypography>
            )}
        </SoftBox>
    );
};

SoftCheckBox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export { SoftCheckBox };
