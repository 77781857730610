import { SUPPORTED_PLATFORMS } from "constants/platforms";
import * as Yup from "yup";

const valueObject = Yup.object().shape({
    // value: Yup.string().required("validation_errors.required"),
    // label: Yup.string().required("validation_errors.required"),
});

/**
 * Yup schema for audio generation
 */
const commonAudioGenerationSchema = Yup.object().shape({
    // Common inputs
    title: Yup.string().required("validation_errors.required"),
    processedText: Yup.string().required("validation_errors.required"),
    voice: valueObject.required("validation_errors.required"),
    language: valueObject.required("validation_errors.required"),
    frequency: Yup.string().required("validation_errors.required"),
    speed: Yup.number().required("validation_errors.required"),
});

const vocalityAudioGenerationSchema = Yup.object().shape({
    colonDur: Yup.number().required("validation_errors.required"),
    ellipsisDur: Yup.number().required("validation_errors.required"),
    exclamationDur: Yup.number().required("validation_errors.required"),
    interrogationDur: Yup.number().required("validation_errors.required"),
    periodDur: Yup.number().required("validation_errors.required"),
    semicolonDur: Yup.number().required("validation_errors.required"),
    product: valueObject.required("validation_errors.required"),
    hardwareUsed: Yup.string().required("validation_errors.required"),
});

const murfAudioGenerationSchema = Yup.object().shape({
    modelVersion: Yup.string().required("validation_errors.required"),
    style: valueObject.required("validation_errors.required"),
    audioFormat: valueObject.required("validation_errors.required"),
    channelType: Yup.string().required("validation_errors.required"),
    pitch: Yup.number().required("validation_errors.required"),
});

const audioGenerationSchema = Yup.lazy((values) => {
    if (values.voice?.metadata?.platform === SUPPORTED_PLATFORMS.VOCALITY) {
        return commonAudioGenerationSchema.concat(vocalityAudioGenerationSchema);
    } else if (values.voice?.metadata?.platform === SUPPORTED_PLATFORMS.MURF) {
        return commonAudioGenerationSchema.concat(murfAudioGenerationSchema);
    }
    return commonAudioGenerationSchema;
});

export { audioGenerationSchema };
