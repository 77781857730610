import { api } from "../app/baseQuery";

/**
 * Product API
 */
const productApi = api.injectEndpoints({
    tagTypes: ["product"],
    endpoints: (build) => ({
        /**
         * Get products - Devuelve los productos de stripe
         */
        getProducts: build.query({
            query: () => "stripe/products/read",
        }),
    }),
    overrideExisting: false,
});

export const { useGetProductsQuery } = productApi;
