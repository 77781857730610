/**
 * Rounds a number to two decimal places
 * @param {*} num - Number to round
 * @returns {number} - Rounded number
 */
const roundToTwoDecimals = (num) => {
    return Math.round((num + Number.EPSILON) * 10000) / 10000;
};

export { roundToTwoDecimals };
