import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";

import { REQUESTSTATE } from "constants/request";

/**
 * Component that displays the status of a request.
 */
const RequestStatusLabel = ({ status }) => {
    const { t } = useTranslation("history");
    return (
        <SoftBox ml={-1.325}>
            <SoftBadgeDot
                size="xs"
                color={status == REQUESTSTATE.COMPLETED ? "success" : "error"}
                badgeContent={
                    status == REQUESTSTATE.COMPLETED
                        ? t("request_status.available")
                        : status == REQUESTSTATE.EXPIRED
                        ? t("request_status.expired")
                        : t("request_status.failed")
                }
            />
        </SoftBox>
    );
};

RequestStatusLabel.propTypes = {
    status: PropTypes.string,
};

export { RequestStatusLabel };
