import SoftBox from "components/SoftBox";

import { FrequencyRadioButtons } from "components/dashboard/vocality/FrecuencyRadioButtons";

import { HardwareRadioButtons } from "components/dashboard/vocality/HardwareRadioButtons";
import { ProductTypeSelector } from "components/dashboard/vocality/ProductTypeSelector";
import { AudioSpeedSlider } from "components/dashboard/vocality/AudioSpeedSlider";
import { AudioPausesSliders } from "components/dashboard/vocality/AudioPausesSliders";
import { VocalityDictionary } from "components/dashboard/vocality/VocalityDictionary";

const VocalityVoiceGenerationInputs = () => {
    return (
        <SoftBox>
            <HardwareRadioButtons />
            <ProductTypeSelector />
            <FrequencyRadioButtons />
            <AudioSpeedSlider />
            <AudioPausesSliders />
            <VocalityDictionary />
        </SoftBox>
    );
};

export { VocalityVoiceGenerationInputs };
