/**
 * Array of all the possible hardware options for the Vocality AI voice cloning service.
 */
const HARDWARE_OPTIONS = ["GPU", "CPU"];

/**
 * Array of all the possible frequency options for the Vocality AI voice cloning service.
 */
const FRECUENCY_OPTIONS = ["24000", "44100", "48000"];

export { HARDWARE_OPTIONS, FRECUENCY_OPTIONS };
