import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetSynthesisRequestsQuery } from "services/inference";
import { useGetInferencesQuery } from "services/inference";
import Swal from "sweetalert2";

/**
 * Hook to get inference request table data
 * @returns
 */
const useInferencesData = () => {
    const { t } = useTranslation("history");
    const {
        data: vocalityInferences,
        error: vocalityInferencesError,
        isFetching: vocalityInferencesIsFetching,
        isSuccess: vocalityInferencesIsSuccess,
    } = useGetInferencesQuery();

    const {
        data: murfSynthesisRequests,
        error: murfSynthesisRequestsError,
        isFetching: murfSynthesisRequestsIsFetching,
        isSuccess: murfSynthesisRequestsIsSuccess,
    } = useGetSynthesisRequestsQuery();

    useEffect(() => {
        if (vocalityInferencesError || murfSynthesisRequestsError) {
            if (vocalityInferencesError) {
                Swal.mixin({
                    toast: true,
                    position: "top-start",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                }).fire({
                    title: t("inference_request.error.title"),
                    text: t("inference_request.error.message"),
                    icon: "error",
                });
            }
        }
    }, [vocalityInferencesError, murfSynthesisRequestsError]);

    const isFetching = useMemo(() => {
        return vocalityInferencesIsFetching || murfSynthesisRequestsIsFetching;
    }, [vocalityInferencesIsFetching, murfSynthesisRequestsIsFetching]);

    const isSuccess = useMemo(() => {
        return vocalityInferencesIsSuccess && murfSynthesisRequestsIsSuccess;
    }, [vocalityInferencesIsSuccess, murfSynthesisRequestsIsSuccess]);

    const inferences = useMemo(() => {
        if (!vocalityInferencesIsSuccess || !murfSynthesisRequestsIsSuccess) return [];
        return [...vocalityInferences, ...murfSynthesisRequests].sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });
    }, [
        vocalityInferencesIsSuccess,
        murfSynthesisRequestsIsSuccess,
        vocalityInferences,
        murfSynthesisRequests,
    ]);

    return {
        inferences,
        isFetching,
        isSuccess,

        vocalityInferences,
        vocalityInferencesError,
        vocalityInferencesIsFetching,
        vocalityInferencesIsSuccess,

        murfSynthesisRequests,
        murfSynthesisRequestsError,
        murfSynthesisRequestsIsFetching,
        murfSynthesisRequestsIsSuccess,
    };
};

export { useInferencesData };
