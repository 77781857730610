import React from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Link } from "react-router-dom";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import InfoButton from "components/InfoButton";
import SoftDropzone from "components/SoftDropzone";

import { MURF_PRONUNCIATION_DICTIONARY } from "constants/files";

const DownloadDictionaryTemplateButton = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    return (
        <SoftBox sx={{ mb: 2 }}>
            <Link to={`/files/${MURF_PRONUNCIATION_DICTIONARY}`} target="_blank" download>
                <SoftButton variant="gradient" color="secondary">
                    {t("download_dictionary_button_text")}
                </SoftButton>
            </Link>
            <InfoButton modal text={t("pronunciation_dictionary.text")} />
        </SoftBox>
    );
};

/**
 * Pronunciation dictionary for murf
 */
const PronunciationDictionary = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({ name: "pronunciationDictionary", control, defaultValue: [] });

    const handleFileOnChange = ({ content }) => {
        field.onChange(content);
    };

    return (
        <SoftBox>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <SoftTypography variant="h6" fontWeight="medium">
                        {t("pronunciation_dictionary_input_label")}
                    </SoftTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <DownloadDictionaryTemplateButton />
                    <SoftBox>
                        <SoftDropzone
                            name="dictionary"
                            options={{
                                autoProcessQueue: true,
                                addRemoveLinks: true,
                                acceptedFiles: ".xls, .xlsx, .csv",
                                maxFiles: 1,
                            }}
                            handler={handleFileOnChange}
                        />
                    </SoftBox>
                    <SoftTypography variant="caption">
                        {t("dictionary_input_caption", {
                            fileName: MURF_PRONUNCIATION_DICTIONARY,
                        })}
                    </SoftTypography>
                </AccordionDetails>
            </Accordion>
        </SoftBox>
    );
};

export { PronunciationDictionary };
