import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const initialState = {
    config: {
        modelType: "Vocality",
        title: "",
        language: "",
        speaker: "",
        frequency: "44100",
        speed: 1,
        speakerList: [],
    },
    vocalityConfig: {
        server: "GPU",
        product: "",
        periodDur: 1.5,
        ellipsisDur: 0.75,
        semicolonDur: 0.75,
        colonDur: 0.75,
        exclamationDur: 1,
        interrogationDur: 1,
        dictionary: [],
        dictionaryText: "",
        processingText: "",
        cloningText: "",
    },
    murfConfig: {
        modelVersion: "GEN2",
        style: "",
        pitch: 0,
        format: "WAV",
        channelType: "MONO",
        variation: 1,
        audioDuration: 0,
        availableStyles: [],
        dictionary: {},
        dictionaryText: "",
        text: "",
        encodeAsBase64: false,
        audioDuration: 0,
    },
};

const ConfigContext = createContext(null);

export const ConfigProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    const setConfig = (newConfig) => {
        setState((prevState) => ({
            ...prevState,
            config: {
                ...prevState.config,
                ...newConfig,
            },
        }));
    };

    const setVocalityConfig = (newVocalityConfig) => {
        setState((prevState) => ({
            ...prevState,
            vocalityConfig: {
                ...prevState.vocalityConfig,
                ...newVocalityConfig,
            },
        }));
    };

    const setMurfConfig = (newMurfConfig) => {
        setState((prevState) => ({
            ...prevState,
            murfConfig: {
                ...prevState.murfConfig,
                ...newMurfConfig,
            },
        }));
    };

    const resetConfig = () => setState(initialState);

    return (
        <ConfigContext.Provider
            value={{ state, setConfig, setVocalityConfig, setMurfConfig, resetConfig }}
        >
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (!context) {
        throw new Error("useConfig must be used within a ConfigProvider");
    }
    return context;
};

ConfigProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
