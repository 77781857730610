import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Link } from "react-router-dom";

import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import InfoButton from "components/InfoButton";
import SoftDropzone from "components/SoftDropzone";

import { VOCALITY_PRONUNCIATION_DICTIONARY } from "constants/files";

const DownloadDictionaryTemplateButton = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    return (
        <SoftBox mb={2}>
            <Link to={`/files/${VOCALITY_PRONUNCIATION_DICTIONARY}`} target="_blank" download>
                <SoftButton variant="gradient" color="secondary">
                    {t("download_dictionary_button_text")}
                </SoftButton>
            </Link>
            <InfoButton modal text={t("dictionary_words.text")} />
        </SoftBox>
    );
};

/**
 * File input for the dictionary file.
 */
const VocalityDictionary = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({
        name: "textProcessingDictionary",
        control,
        defaultValue: [],
    });

    const handleFileOnChange = ({ content }) => {
        field.onChange(content);
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <SoftTypography variant="h6" fontWeight="medium">
                    {t("dictionary_input_label")}
                </SoftTypography>
            </AccordionSummary>
            <AccordionDetails>
                <DownloadDictionaryTemplateButton />
                <SoftBox>
                    <SoftDropzone
                        name="dictionary"
                        options={{
                            inputProps: {
                                accept: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                multiple: false,
                            },
                        }}
                        handler={handleFileOnChange}
                    />
                </SoftBox>
                <SoftTypography variant="caption">
                    {t("dictionary_input_caption", {
                        fileName: VOCALITY_PRONUNCIATION_DICTIONARY,
                    })}
                </SoftTypography>
            </AccordionDetails>
        </Accordion>
    );
};

export { VocalityDictionary };
