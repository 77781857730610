import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { Card } from "@mui/material";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

/**
 * Basic Dashboard Layout
 */
const BasicDashboardLayout = ({ children }) => {
    const location = useLocation();
    localStorage.setItem("last_visited", location?.pathname || "/");

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card sx={{ padding: "2rem" }}>{children}</Card>
            <Footer />
        </DashboardLayout>
    );
};

BasicDashboardLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export { BasicDashboardLayout };
