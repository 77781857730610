/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "components/SoftSelect/styles";

const SoftSelect = forwardRef(({ size, error, success, ...rest }, ref) => {
    const { light } = colors;
    const { t } = useTranslation("form");

    return (
        <Select
            {...rest}
            ref={ref}
            noOptionsMessage={() => t("no_options")}
            styles={styles(size, error, success)}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: light.main,
                    primary: light.main,
                },
            })}
        />
    );
});

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
    size: "medium",
    error: false,
    success: false,
};

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    error: PropTypes.bool,
    success: PropTypes.bool,
};

export default SoftSelect;
