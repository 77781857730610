import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import VocalitySlider from "components/Forms/VocalitySlider";

/**
 * Pitch slider component for adjusting the speed of the audio.
 */
const AudioPitchSlider = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({ name: "pitch", control, defaultValue: 1.0 });

    return (
        <SoftBox>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("pitch_options_input_label")}
            </SoftTypography>
            <VocalitySlider
                min={-50}
                max={50}
                step={1}
                marks
                value={field.value}
                onChange={field.onChange}
            />
        </SoftBox>
    );
};

export { AudioPitchSlider };
