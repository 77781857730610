import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import InfoButton from "components/InfoButton";

import { useVoices } from "hooks/useVoices";
import { getAudioSamplePath } from "utils/files";
import { getAudioSampleUrlFromMurf } from "utils/murf";

import { AudioPlayer } from "components/AudioPlayer";
import { SUPPORTED_PLATFORMS } from "constants/platforms";

/**
 * VoiceSelector component for the VoiceGenerationDashboard page.
 */
const VoiceSelector = () => {
    const { t, i18n } = useTranslation(["generate_voice_dashboard", "locales"]);
    const { control } = useFormContext();

    const [audioSampleSource, setAudioSampleSource] = useState(null);

    const { field: languageField } = useController({ name: "language", control });
    const { field: voiceField } = useController({ name: "voice", control });

    const { availableLanguages, getVocalityVoicesByLanguage, getMurfVoicesByLanguage } =
        useVoices();

    const handleLanguageChange = (selectedOption) => {
        languageField.onChange(selectedOption);
        voiceField.onChange(null);
    };

    const availableLanguageOptions = useMemo(() => {
        return availableLanguages
            .map((language) => ({
                value: language,
                label: t(language, { ns: "locales" }),
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [availableLanguages, i18n.language]);

    const availableVoiceOptions = useMemo(() => {
        if (!languageField.value) return [];
        const options = [];
        options.push.apply(
            options,
            getVocalityVoicesByLanguage(languageField.value.value).map((voice) => ({
                value: voice.name,
                label: voice.name,
                metadata: {
                    platform: SUPPORTED_PLATFORMS.VOCALITY,
                    voiceMetadata: voice.voice_metadata,
                },
            }))
        );
        options.push.apply(
            options,
            getMurfVoicesByLanguage(languageField.value.value).map((voice) => ({
                value: voice.voiceId,
                label: voice.displayName,
                metadata: {
                    platform: SUPPORTED_PLATFORMS.MURF,
                    availableStyles:
                        voice.supportedLocales[languageField.value.value].availableStyles,
                },
            }))
        );
        return options;
    }, [languageField.value]);

    useEffect(() => {
        if (!voiceField.value) return setAudioSampleSource(null);
        setAudioSampleSource(
            voiceField.value.metadata.platform === SUPPORTED_PLATFORMS.VOCALITY
                ? getAudioSamplePath(voiceField.value.value)
                : getAudioSampleUrlFromMurf(voiceField.value.value)
        );
    }, [voiceField.value]);

    return (
        <>
            <SoftBox>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("language_input_label")}
                </SoftTypography>
                <SoftSelect
                    placeholder={t("language_input_placeholder")}
                    value={languageField.value}
                    onChange={handleLanguageChange}
                    options={availableLanguageOptions}
                    ref={languageField.ref}
                    key={`language-select-${languageField.value}`}
                />
            </SoftBox>
            <SoftBox>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("speaker_input_label")}
                    {voiceField.value?.metadata?.voiceMetadata && (
                        <InfoButton
                            modal
                            text={voiceField.value.metadata.voiceMetadata[i18n.language]}
                            key={`info-${i18n.language}-voice-metadata`}
                        />
                    )}
                </SoftTypography>
                <SoftSelect
                    placeholder={t("speaker_input_placeholder")}
                    value={voiceField.value}
                    onChange={voiceField.onChange}
                    options={availableVoiceOptions}
                    ref={voiceField.ref}
                    key={`voice-select-${voiceField.value}`}
                />
            </SoftBox>
            <SoftBox mb={2}>
                {audioSampleSource && (
                    <AudioPlayer source={audioSampleSource} key={audioSampleSource} />
                )}
            </SoftBox>
        </>
    );
};

VoiceSelector.propTypes = {};

export { VoiceSelector };
