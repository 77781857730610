import { useMemo } from "react";

import ExportDataTable from "components/History/Tables/ExportableInferenceTable";
import { useExcelExport } from "hooks/useExcelExport";

import { useTranslation } from "react-i18next";
import { groupBy } from "utils/iterable";
import { useInferenceRequestTable } from "hooks/useInferenceTable";

/**
 * Inference request table component
 */
const ClientInferenceBillingTable = () => {
    const { t } = useTranslation(["inferences", "history"]);

    const columns = useMemo(
        () => ({
            "title": { Header: t("table.headers.title"), accessor: "title" },
            "user": { Header: t("table.headers.user"), accessor: "user" },
            "product": {
                Header: t("table.headers.product_type"),
                accessor: "product",
            },
            "language": {
                Header: t("table.headers.language"),
                accessor: "language",
            },
            "speaker": {
                Header: t("table.headers.speaker"),
                accessor: "speaker",
            },
            "creation_date": {
                Header: t("table.headers.creation_date"),
                accessor: "creation_date",
            },
            "creation_year": {
                Header: t("table.headers.creation_year"),
                accessor: "creation_year",
            },
            "creation_month": {
                Header: t("table.headers.creation_month"),
                accessor: "creation_month",
            },
            "status": {
                Header: t("table.headers.status"),
                accessor: "status",
            },
            "expiration_date": {
                Header: t("table.headers.expiration_date"),
                accessor: "expiration_date",
            },
            "duration": {
                Header: t("table.headers.duration"),
                accessor: "duration",
            },
            "price": {
                Header: t("table.headers.price"),
                accessor: "price",
            },
            "total_price": {
                Header: t("table.headers.total_price"),
                accessor: "total_price",
            },
        }),
        [t]
    );

    const { dataTableRows } = useInferenceRequestTable({
        columns: Object.keys(columns),
    });

    const { downloadWorkbook } = useExcelExport();

    const exportToExcel = (data) => {
        const dataGroupedByUser = groupBy(data, (item) => item.user);
        downloadWorkbook({ data: dataGroupedByUser, fileName: t("billing") });
    };

    return (
        <ExportDataTable
            canSearch
            table={{
                columns: Object.values(columns),
                rows: dataTableRows,
            }}
            exportToExcel={exportToExcel}
            customFilters={[
                { accessor: "user", defaultLabel: t("filters.all_users", { ns: "history" }) },
            ]}
        />
    );
};

ClientInferenceBillingTable.propTypes = {};

export { ClientInferenceBillingTable };
