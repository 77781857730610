/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useFilters,
    useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { useTranslation } from "react-i18next";

import { MONTHS } from "constants/time";

const DataTableFilter = ({ data, accessor, defaultLabel, filters, setFilter }) => {
    const handleFilterChange = (selectedOption) => {
        if (selectedOption.value == "default") {
            setFilter(accessor, undefined);
        } else {
            setFilter(accessor, selectedOption.label);
        }
    };

    const filterOptions = useMemo(() => {
        const uniqueOptionsList = Array.from(new Set(data.map((row) => row[accessor])));
        return [
            { value: "default", label: defaultLabel },
            ...uniqueOptionsList.map((user) => ({ value: user, label: user })),
        ];
    }, [data, accessor, defaultLabel]);

    const selectedFilterOption = useMemo(() => {
        const selectedOptions = filters.find((option) => option.id === accessor);
        return {
            value: selectedOptions ? selectedOptions.value : "default",
            label: selectedOptions ? selectedOptions.value : defaultLabel,
        };
    }, [filters, accessor, defaultLabel]);

    return (
        <SoftSelect
            value={selectedFilterOption}
            onChange={handleFilterChange}
            options={filterOptions}
        />
    );
};

DataTableFilter.propTypes = {
    data: PropTypes.array.isRequired,
    accessor: PropTypes.string.isRequired,
    defaultLabel: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    setFilter: PropTypes.func.isRequired,
};

function ExportDataTable({
    entriesPerPage,
    canSearch,
    showTotalEntries,
    table,
    pagination,
    isSorted,
    noEndBorder,
    exportToExcel,
    customFilters,
}) {
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);

    const { t, i18n } = useTranslation(["history", "months", "components"]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        setFilter,
        state: { pageIndex, pageSize, globalFilter, filters },
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

    // Set the entries per page value based on the select value
    const setEntriesPerPage = ({ value }) => setPageSize(value);

    // Render the paginations
    const renderPagination = pageOptions.map((option) => (
        <SoftPagination
            item
            key={option}
            onClick={() => gotoPage(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </SoftPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({ target: { value } }) =>
        value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);

    // Setting value for the pagination input
    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

    // Search input value state
    const [search, setSearch] = useState(globalFilter);

    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    //---------------FILTERING-----------------------------------------------
    const defaultMonthOption = useMemo(() => {
        return {
            value: "all_months",
            label: t("filters.all_months", { ns: "history" }),
        };
    }, [t]);

    const selectedYearFilterOptions = useMemo(() => {
        const selectedOptions = filters.find((option) => option.id === "creation_year");
        return {
            value: selectedOptions ? selectedOptions.value : "all_years",
            label: selectedOptions ? selectedOptions.value : t("filters.all_years"),
        };
    }, [filters, t]);

    const monthFilterOptions = useMemo(() => {
        if (!data) return [];

        // Current year
        if (selectedYearFilterOptions.value == new Date().getFullYear()) {
            const currentMonth = new Date().getMonth();
            return ["all_months", ...MONTHS].slice(0, currentMonth + 2).map(function (element) {
                return {
                    value: element,
                    label: t(element, { ns: "months" }),
                };
            });
            // Previous years
        } else {
            return ["all_months", ...MONTHS].map(function (element) {
                return {
                    value: element,
                    label: t(element, { ns: "months" }),
                };
            });
        }
    }, [data, t, selectedYearFilterOptions]);

    const selectedMonthFilterOption = useMemo(() => {
        const selectedOptions = filters.find((option) => option.id === "creation_month");
        return {
            value: selectedOptions ? selectedOptions.value : "all_months",
            label: selectedOptions
                ? t(selectedOptions.value, { ns: "months" })
                : t("filters.all_months"),
        };
    }, [filters, t]);

    useEffect(() => {
        handleMonthFilterChange(defaultMonthOption);
    }, [i18n.language]);

    const handleMonthFilterChange = (selectedOption) => {
        if (selectedOption.value == "all_months") {
            setFilter("creation_month", undefined);
        } else {
            setFilter("creation_month", selectedOption.label);
        }
    };

    // Export Excel
    function handleOnExportToExcel() {
        exportToExcel(rows.map((row) => row.original));
    }
    //------------------------------------------------------------------------

    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            <Grid container spacing={3} mt={1} display="flex" justifyContent="flex-end">
                <Grid item xs={6} lg={2}>
                    <DataTableFilter
                        data={data}
                        accessor="creation_year"
                        defaultLabel={t("filters.all_years")}
                        filters={filters}
                        setFilter={setFilter}
                    />
                </Grid>
                <Grid item xs={6} lg={2}>
                    <SoftSelect
                        value={selectedMonthFilterOption}
                        onChange={handleMonthFilterChange}
                        options={monthFilterOptions}
                    />
                </Grid>
                {customFilters &&
                    customFilters.map((filter, index) => (
                        <Grid item xs={6} lg={2} key={index}>
                            <DataTableFilter
                                data={data}
                                accessor={filter.accessor}
                                defaultLabel={filter.defaultLabel}
                                filters={filters}
                                setFilter={setFilter}
                            />
                        </Grid>
                    ))}
                <Grid item xs={6} lg={2}>
                    <SoftButton
                        variant="gradient"
                        color="secondary"
                        onClick={handleOnExportToExcel}
                        fullWidth
                    >
                        {t("export_to_excel_button")}
                    </SoftButton>
                </Grid>
            </Grid>
            {entriesPerPage || canSearch ? (
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {entriesPerPage && (
                        <SoftBox display="flex" alignItems="center">
                            <SoftSelect
                                defaultValue={{ value: defaultValue, label: defaultValue }}
                                options={entries.map((entry) => ({ value: entry, label: entry }))}
                                onChange={setEntriesPerPage}
                                size="small"
                            />
                            <SoftTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;{t("table.entries_per_page", { ns: "components" })}
                            </SoftTypography>
                        </SoftBox>
                    )}
                    {canSearch && (
                        <SoftBox width="12rem" ml="auto">
                            <SoftInput
                                placeholder={t("table.search_placeholder", { ns: "components" })}
                                value={search}
                                onChange={({ currentTarget }) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </SoftBox>
                    )}
                </SoftBox>
            ) : null}
            <Table {...getTableProps()}>
                <SoftBox component="thead">
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, key) => (
                                <DataTableHeadCell
                                    key={key}
                                    {...column.getHeaderProps(
                                        isSorted && column.getSortByToggleProps()
                                    )}
                                    width={column.width ? column.width : "auto"}
                                    align={column.align ? column.align : "left"}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow key={key} {...row.getRowProps()}>
                                {row.cells.map((cell, key) => (
                                    <DataTableBodyCell
                                        key={key}
                                        noBorder={noEndBorder && rows.length - 1 === key}
                                        align={cell.column.align ? cell.column.align : "left"}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{ xs: 3, sm: 0 }}>
                        <SoftTypography variant="button" color="secondary" fontWeight="regular">
                            {t("table.showing_entries", {
                                ns: "components",
                                from: entriesStart,
                                to: entriesEnd,
                                total: rows.length,
                            })}
                        </SoftTypography>
                    </SoftBox>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={() => previousPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </SoftPagination>
                        )}
                        {renderPagination.length > 6 ? (
                            <SoftBox width="5rem" mx={1}>
                                <SoftInput
                                    inputProps={{
                                        type: "number",
                                        min: 1,
                                        max: customizedPageOptions.length,
                                    }}
                                    value={customizedPageOptions[pageIndex]}
                                    onChange={(handleInputPagination, handleInputPaginationValue)}
                                />
                            </SoftBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <SoftPagination item onClick={() => nextPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

// Setting default values for the props of ExportDataTable
ExportDataTable.defaultProps = {
    entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
};

// Typechecking props for the ExportDataTable
ExportDataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
    exportToExcel: PropTypes.func.isRequired,
    customFilters: PropTypes.Array,
};

export default ExportDataTable;
