import { api } from "../app/baseQuery";

const inferenceApi = api.injectEndpoints({
    tagTypes: ["Inference"],
    endpoints: (build) => ({
        // Get all inference requests
        getInferences: build.query({
            query: () => "db/inference-requests/read",
            providesTags: ["Inference"],
        }),

        // Get inference requests by user
        getInferenceByUser: build.query({
            query: () => "db/inference-requests/read",
            providesTags: ["Inference"],
        }),

        // Get inference requests by inference id
        getInferenceByInferenceId: build.query({
            query: (inferenceId) => `db/inference-requests/read/${inferenceId}`,
        }),

        // Get inference requests by customer id
        getInferenceByCustomerId: build.query({
            query: (customerId) => `db/inference-requests/read-by-customer/${customerId}`,
            providesTags: ["Inference"],
        }),

        // Create inference request
        createInferenceRequest: build.mutation({
            query: (data) => {
                const formBody = new URLSearchParams(data).toString();

                return {
                    url: "ai/inference/request",
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    body: formBody,
                };
            },
            invalidatesTags: ["Inference"],
        }),
    }),
    overrideExisting: false,
});

const synthesisApi = api.injectEndpoints({
    tagTypes: ["Synthesis"],
    endpoints: (build) => ({
        // Get murf synthesis requests
        getSynthesisRequests: build.query({
            query: () => "/db/murf/synthesis-requests/read",
            providesTags: ["Synthesis"],
        }),

        // Get murf synthesis requests by synthesis request ID
        getSynthesisRequestBySynthesisRequestId: build.query({
            query: (synthesisRequestId) => `/db/murf/synthesis-requests/read/${synthesisRequestId}`,
            providesTags: ["Synthesis"],
        }),

        // Create a voice synthesis request
        createSynthesisRequest: build.mutation({
            query: (data) => {
                const formBody = new URLSearchParams(data).toString();

                return {
                    url: "murf/synthesis",
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    body: formBody,
                };
            },
            invalidatesTags: ["Synthesis"],
        }),

        // Get audio URL by synthesis request ID
        getAudioUrl: build.query({
            query: (synthesisRequestId) => `murf/audio/${synthesisRequestId}`,
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetInferencesQuery,
    useGetInferenceByUserQuery,
    useGetInferenceByInferenceIdQuery,
    useGetInferenceByCustomerIdQuery,
    useCreateInferenceRequestMutation,
} = inferenceApi;

export const {
    useCreateSynthesisRequestMutation,
    useGetAudioUrlQuery,
    useGetSynthesisRequestsQuery,
    useGetSynthesisRequestBySynthesisRequestIdQuery,
} = synthesisApi;
