import Swal from "sweetalert2";

/**
 * Function to process text with a dictionary of words and their phonetic pronunciation
 * @param {string} unprocessedText - Text to be processed
 * @param {array} dictionary - Array of objects with the following structure:
 * {
 *      "Palabra": "word",
 *      "Fonética correcta": "phonetic pronunciation",
 *      "Palabra entera": true/false,
 *      "Distingue capitalización": true/false
 * }
 */
const processVocalityText = ({ unprocessedText, dictionary }) => {
    // VARIABLES
    let transformText = unprocessedText;
    // aux text strings to not replace final transformed text
    let headTransText = "";
    let tailTransText = transformText;
    // length of word -> useful for replacing word with phonetic pronountiation
    let wordlen = "";
    // variable resulting from checking if there is a match in the text with the word
    let regexparray = "";
    // substring which contains word + character right before it + character right after it; if the word must be whole we check it using this
    let auxsubstring = "";
    // auxiliary variable in order to not directly use dictionary word
    let auxword = "";
    // we compare this to the matching substring. Important for case sensitivenss
    let pureAuxWord = "";
    // removal of .!? characters existing in word["Palabra"] to not confuse the code
    let temporaryperiodremove = "";
    // text which appears in error notif (if there is one)
    let processErrorText = "";

    //ORDER DICTIONARY BY LENGTH OF WORDS, case sensitiveness and whether it's a whole word or not
    dictionary.sort((a, b) => b["Palabra entera"] - a["Palabra entera"]);
    dictionary.sort((a, b) => b["Palabra"].length - a["Palabra"].length);
    dictionary.sort((a, b) => b["Distingue capitalización"] - a["Distingue capitalización"]);

    //REPLACING TEXT TO PHONETIC SOUND
    dictionary.forEach((word) => {
        auxword = word["Palabra"];
        wordlen = auxword.length;
        headTransText = "";
        tailTransText = transformText;

        if (word["Distingue capitalización"] == false) {
            auxword = auxword.toLowerCase();
            tailTransText = tailTransText.toLowerCase();
        } else if (word["Distingue capitalización"] != true) {
            processErrorText =
                'Existen filas con un valor ambiguo en la columna "Distingue capitalización"';
        }

        pureAuxWord = auxword;

        if (auxword.search(/[\[\]*+|{}\\()@\n\r]/) != -1) {
            auxword = auxword.replace(/[\[\]*+|{}\\()@\n\r]/g, "\\$&");
        }

        regexparray = RegExp(auxword, "g").exec(tailTransText);
        do {
            if (regexparray && processErrorText == "") {
                if (word["Palabra entera"] == true) {
                    //Periods, exclamation and question maerks can be at end of word we want to check as whole word
                    temporaryperiodremove = auxword
                        .replace(/\./g, "_TRANSFORMPERIOD_")
                        .replace(/\?/g, "_TRANSFORMQUESTION_")
                        .replace(/\!/g, "_TRANSFORMEXCLAMATION_");
                    auxsubstring = tailTransText
                        .substring(regexparray.index - 1, regexparray.index + wordlen + 1)
                        .replace(auxword, temporaryperiodremove);
                    if (
                        auxsubstring.charAt(auxsubstring.length - 1) == "." ||
                        auxsubstring.charAt(auxsubstring.length - 1) == "!" ||
                        auxsubstring.charAt(auxsubstring.length - 1) == "?"
                    ) {
                        auxsubstring = auxsubstring.replace(/.$/, " ");
                    }

                    if (
                        auxsubstring
                            .trim()
                            .replace(/_TRANSFORMPERIOD_/g, ".")
                            .replace(/_TRANSFORMQUESTION_/g, "?")
                            .replace(/_TRANSFORMEXCLAMATION_/g, "!") == pureAuxWord
                    ) {
                        headTransText =
                            headTransText +
                            tailTransText.substring(0, regexparray.index) +
                            word["Fonética correcta"];
                        tailTransText = tailTransText.substring(
                            regexparray.index + wordlen,
                            tailTransText.length
                        );
                    } else {
                        headTransText = headTransText + tailTransText;
                        tailTransText = "";
                    }
                } else if (word["Palabra entera"] == false) {
                    auxsubstring = tailTransText.substring(
                        regexparray.index,
                        regexparray.index + wordlen
                    );

                    if (auxsubstring == pureAuxWord) {
                        headTransText =
                            headTransText +
                            tailTransText.substring(0, regexparray.index) +
                            word["Fonética correcta"];
                        tailTransText = tailTransText.substring(
                            regexparray.index + wordlen,
                            tailTransText.length
                        );
                    } else {
                        headTransText = headTransText + tailTransText;
                        tailTransText = "";
                    }
                } else {
                    processErrorText =
                        'Existen filas con un valor ambiguo en la columna "Palabra entera"';
                }
                //Set regexparray again for more loop iterations if word exists several times
                if (word["Distingue capitalización"] == false) {
                    auxword = auxword.toLowerCase();
                    tailTransText = tailTransText.toLowerCase();
                }

                regexparray = RegExp(auxword, "g").exec(tailTransText);
            }
        } while (regexparray && processErrorText == "");
        transformText = headTransText + tailTransText;
    });
    if (processErrorText == "") {
        return transformText.replace(/\s+/g, " ");
    } else {
        Swal.fire("Error", processErrorText, "error");
        return "";
    }
};

export { processVocalityText as processVocalityDictionary };
