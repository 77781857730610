import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { downloadResource } from "utils/download";

/**
 * Dashboard button to download the generated audio.
 */
const AudioDownloadButton = ({ audioUrl, mode = "link", filename = "audio" }) => {
    const { t } = useTranslation("generate_voice_dashboard");

    const handleClick = () => {
        if (mode === "link") {
            window.location.href = audioUrl;
        } else if (mode === "fetch") {
            downloadResource(audioUrl, filename);
        } else {
            throw new Error("Invalid mode");
        }
    };

    return (
        <SoftButton variant="contained" color="dark" onClick={handleClick}>
            {t("download_generated_voice_button_text")}
        </SoftButton>
    );
};

AudioDownloadButton.propTypes = {
    audioUrl: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["link", "fetch"]),
    filename: PropTypes.string,
};

export { AudioDownloadButton };
