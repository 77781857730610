/**
 * Resolves the audio sample path based on the provided voice Name and voices list
 * @param {string} voiceName The voice name
 * @returns {string} The resolved audio sample path
 */
function getAudioSamplePath(voiceName) {
    return `/files/voice_samples/${voiceName}.mp3`;
}

export { getAudioSamplePath };
