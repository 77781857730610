import Swal from "sweetalert2";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useRegisterUserMutation } from "services/auth";
import { PATHS } from "constants/paths";

/**
 * Auth hook for showing registration modal success/error messages
 */
export const useRegister = () => {
    const { t } = useTranslation("register");
    const navigate = useNavigate();

    const [registerUser, { isLoading, isSuccess, error }] = useRegisterUserMutation();

    useEffect(() => {
        if (isSuccess) {
            Swal.fire(
                t("form.success.modal_title"),
                t("form.success.modal_message"),
                "success"
            ).then(() => {
                navigate(PATHS.signIn);
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            Swal.fire(t("form.error.modal_title"), t(`form.error.${error.message}`), "error");
        }
    }, [error]);

    return {
        isSuccess,
        isLoading,
        registerUser,
    };
};
