import { useTranslation } from "react-i18next";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

function LegalText() {
    // Traducción
    const { t } = useTranslation("legal_text");

    return (
        <SoftBox position="relative" py={0.25}>
            {/* Política de Privacidad */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <SoftTypography>{t("privacy_policy.title")}</SoftTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <SoftTypography
                        variant="body2"
                        component="span"
                        dangerouslySetInnerHTML={{ __html: t("privacy_policy.content") }}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Política de Cookies */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                >
                    <SoftTypography>{t("cookie_policy.title")}</SoftTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <SoftTypography
                        variant="body2"
                        component="span"
                        dangerouslySetInnerHTML={{ __html: t("cookie_policy.content") }}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Política de Contenido */}
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    <SoftTypography>{t("content_policy.title")}</SoftTypography>
                </AccordionSummary>
                <AccordionDetails>
                    <SoftTypography
                        variant="body2"
                        component="span"
                        dangerouslySetInnerHTML={{ __html: t("content_policy.content") }}
                    />
                </AccordionDetails>
            </Accordion>

            {/* Pie de página */}
            <SoftTypography variant="caption" component="span" sx={{ mt: 1 }}>
                <i>{t("footer")}</i>
            </SoftTypography>
        </SoftBox>
    );
}

export { LegalText };
