import PropTypes from "prop-types";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DefaultLineChart from "examples/Charts/DefaultLineChart";
import { MONTHS } from "constants/time";

const InferenceMinutesHistoryChart = ({ inferences }) => {
    const { t } = useTranslation(["history", "months"]);

    const data = useMemo(() => {
        return Array.from({ length: 12 }, (_, month) => month).map((month) => {
            const monthData = inferences.filter(
                (inference) => new Date(inference.created_at).getMonth() === month
            );
            const monthlyAcc = monthData.reduce((acc, row) => acc + row.audio_length, 0) / 60;
            return monthlyAcc > 0 ? monthlyAcc : null;
        });
    }, [inferences]);

    const chartData = useMemo(
        () => ({
            labels: MONTHS.map((month) => t(month, { ns: "months" })),
            datasets: [
                {
                    label: t("charts.minutes_label"),
                    color: "info",
                    data,
                },
            ],
        }),
        [data, t]
    );

    return <DefaultLineChart title={t("charts.minutes_history_title")} chart={chartData} />;
};

InferenceMinutesHistoryChart.propTypes = {
    inferences: PropTypes.array.isRequired,
};

export { InferenceMinutesHistoryChart };
