import * as XLSX from "xlsx/xlsx.mjs";

/**
 * Parse an array excel file to JSON
 * @param {string} file The excel file as a string
 * @param {*} handler The handler to call with the JSON object
 */
const parseExcelFileToJSON = (file, handler, type) => {
    const reader = new FileReader();

    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        workbook.SheetNames.forEach((sheetName) => {
            const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            handler({ file, sheetName, content: XL_row_object, error: null });
        });
    };

    reader.onerror = (error) => {
        console.log(error);
        handler({ file, content: null, error });
    };

    reader.readAsBinaryString(file);
};

export { parseExcelFileToJSON };
