import { useTranslation } from "react-i18next";

import IconButton from "@mui/material/IconButton";

import SoftBox from "components/SoftBox";

import { MultiLanguageSelector } from "./MultiLanguageSelector";

// Language flags
import ES from "assets/images/icons/flags/es.png";
import GB from "assets/images/icons/flags/gb.png";

import { LANGUAGES } from "constants/languages";

/**
 * LanguageSelector component
 */
const LanguageSelector = (props) => {
    const { i18n } = useTranslation("languages");

    const onLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };

    // TODO: Remove this when the theme is implemented
    const light = false;

    return (
        <SoftBox color={light ? "white" : "inherit"} {...props}>
            {i18n.language === LANGUAGES.SPANISH ? (
                <IconButton onClick={() => onLanguageChange(LANGUAGES.ENGLISH)}>
                    <SoftBox component="img" src={GB} alt="master card" />
                </IconButton>
            ) : (
                <IconButton onClick={() => onLanguageChange(LANGUAGES.SPANISH)}>
                    <SoftBox component="img" src={ES} alt="master card" />
                </IconButton>
            )}
        </SoftBox>
    );
};

export { LanguageSelector, MultiLanguageSelector };
