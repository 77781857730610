import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SoftTypography from "components/SoftTypography";

import VocalitySlider from "components/Forms/VocalitySlider";

/**
 * Accordion component that contains sliders for the different types of pauses.
 * @returns
 */
const AudioPausesSliders = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();

    const { field: periodDurField } = useController({
        name: "periodDur",
        control,
        defaultValue: 0.5,
    });
    const { field: ellipsisDurField } = useController({
        name: "ellipsisDur",
        control,
        defaultValue: 0.5,
    });
    const { field: semicolonDurField } = useController({
        name: "semicolonDur",
        control,
        defaultValue: 0.5,
    });
    const { field: colonDurField } = useController({
        name: "colonDur",
        control,
        defaultValue: 0.5,
    });
    const { field: exclamationDurField } = useController({
        name: "exclamationDur",
        control,
        defaultValue: 0.5,
    });
    const { field: interrogationDurField } = useController({
        name: "interrogationDur",
        control,
        defaultValue: 0.5,
    });

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <SoftTypography variant="h6" fontWeight="medium">
                    {t("pauses_duration_input_label")}
                </SoftTypography>
            </AccordionSummary>
            <AccordionDetails>
                <VocalitySlider
                    min={0}
                    max={2}
                    step={0.05}
                    marks={true}
                    title={t("Period_pause_duration_input_label")}
                    value={periodDurField.value}
                    onChange={periodDurField.onChange}
                />
                <Divider />
                <VocalitySlider
                    min={0}
                    max={2}
                    step={0.05}
                    marks={true}
                    title={t("Ellipsis_point_pause_duration_input_label")}
                    value={ellipsisDurField.value}
                    onChange={ellipsisDurField.onChange}
                />
                <Divider />
                <VocalitySlider
                    min={0}
                    max={2}
                    step={0.05}
                    marks={true}
                    title={t("Semicolon_pause_duration_input_label")}
                    value={semicolonDurField.value}
                    onChange={semicolonDurField.onChange}
                />
                <Divider />
                <VocalitySlider
                    min={0}
                    max={2}
                    step={0.05}
                    marks={true}
                    title={t("Colon_pause_duration_input_label")}
                    value={colonDurField.value}
                    onChange={colonDurField.onChange}
                />
                <Divider />
                <VocalitySlider
                    min={0}
                    max={2}
                    step={0.05}
                    marks={true}
                    title={t("Exclamation_mark_pause_duration_input_label")}
                    value={exclamationDurField.value}
                    onChange={exclamationDurField.onChange}
                />
                <Divider />
                <VocalitySlider
                    min={0}
                    max={2}
                    step={0.05}
                    marks={true}
                    title={t("Question_mark_pause_duration_input_label")}
                    value={interrogationDurField.value}
                    onChange={interrogationDurField.onChange}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export { AudioPausesSliders };
