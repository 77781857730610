import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";

import { HARDWARE_OPTIONS } from "constants/vocality";
import SoftTypography from "components/SoftTypography";

const HardwareRadioButtons = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();
    const { field } = useController({
        name: "hardwareUsed",
        control,
        defaultValue: HARDWARE_OPTIONS[0],
    });

    return (
        <SoftBox mb={2}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("hardware_options_input_label")}
            </SoftTypography>
            <SoftBox ml={2}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="hardware-row-radio-buttons-group-label"
                        value={field.value}
                        onChange={field.onChange}
                        name="row-radio-buttons-group"
                    >
                        <Stack direction="row" sx={{ gap: "3rem" }}>
                            {HARDWARE_OPTIONS.map((hardwareOption) => (
                                <FormControlLabel
                                    key={hardwareOption}
                                    value={hardwareOption}
                                    control={<Radio sx={{ marginRight: "0.25rem" }} />}
                                    label={hardwareOption}
                                />
                            ))}
                        </Stack>
                    </RadioGroup>
                </FormControl>
            </SoftBox>
        </SoftBox>
    );
};

export { HardwareRadioButtons };
