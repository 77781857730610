import SoftBox from "components/SoftBox";
import { TextFileUploader } from "../TextFileUploader";
import { useController, useFormContext } from "react-hook-form";
import SoftInput from "components/SoftInput";
import { useTranslation } from "react-i18next";
import SoftButton from "components/SoftButton";
import { processVocalityDictionary } from "utils/text";
import SoftTypography from "components/SoftTypography";

/**
 *
 * @returns
 */
const VocalityAudioTextEditor = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control, getValues } = useFormContext();
    const { field: fieldUnprocessedText } = useController({
        name: "unprocessedText",
        control,
        defaultValue: "",
    });
    const { field: fieldProcessedText } = useController({
        name: "processedText",
        control,
    });

    const handleProcessText = () => {
        const processedtext = processVocalityDictionary({
            unprocessedText: fieldUnprocessedText.value,
            dictionary: getValues("textProcessingDictionary"),
        });
        fieldProcessedText.onChange(processedtext);
    };

    const handleTextFileUpload = ({ content }) => {
        fieldUnprocessedText.onChange(content);
    };

    return (
        <SoftBox>
            <SoftBox mb={2}>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("text_to_generate")}
                </SoftTypography>
                <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                    {t("text_file_upload_label")}
                </SoftTypography>
            </SoftBox>
            <TextFileUploader handler={handleTextFileUpload} />
            <SoftBox mb={2}>
                <SoftInput
                    id="process-text"
                    placeholder={t("text_unprocessed_placeholder")}
                    multiline
                    value={fieldUnprocessedText.value}
                    onChange={fieldUnprocessedText.onChange}
                    rows={6}
                />
            </SoftBox>
            <SoftBox mb={6}>
                <SoftButton
                    variant="gradient"
                    color="primary"
                    onClick={handleProcessText}
                    fullWidth
                >
                    {t("process_text_button_text")}
                </SoftButton>
            </SoftBox>
            <SoftBox mb={2}>
                <SoftInput
                    id="clone-text"
                    placeholder={t("processed_text_placeholder")}
                    multiline
                    value={fieldProcessedText.value}
                    onChange={fieldProcessedText.onChange}
                    rows={6}
                />
            </SoftBox>
        </SoftBox>
    );
};

export { VocalityAudioTextEditor };
