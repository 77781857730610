import React from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";

/**
 * Audio Player Component
 * @param {string} id - The id of the audio player
 * @param {string} source - The source of the audio file
 * @param {string} type - The type of the audio file
 */
const AudioPlayer = ({ id, source, type = "audio/wav" }) => {
    return (
        <SoftBox sx={{ display: "flex", alignItems: "center" }}>
            <audio controls controlsList="nodownload" id={id} style={{ width: "100%" }}>
                <source src={source} type={type} />
            </audio>
        </SoftBox>
    );
};

AudioPlayer.propTypes = {
    id: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
};

export { AudioPlayer };
