import { VOCALITY_VOICE_LANGUAGE_MAPPING } from "constants/languages";
import { useCallback, useEffect, useState } from "react";
import {
    useCreateInferenceRequestMutation,
    useGetInferenceByInferenceIdQuery,
} from "services/inference";

/**
 * Hook for generating vocality audio
 */
const useGenerateVocalityAudio = () => {
    const [isPollingActive, setIsPollingActive] = useState(false);

    // Vocality API request
    const [
        requestAudioInference,
        {
            data: inferenceRequestData,
            error: inferenceRequestError,
            isSuccess: inferenceRequestIsSuccess,
            reset: resetInferenceRequest,
        },
    ] = useCreateInferenceRequestMutation();

    const {
        data: inferenceQueryData,
        isSuccess: inferenceQueryIsSuccess,
        error: inferenceQueryError,
    } = useGetInferenceByInferenceIdQuery(inferenceRequestData?.request_id, {
        pollingInterval: isPollingActive ? 3000 : 0,
        skip: !isPollingActive,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (inferenceRequestIsSuccess && inferenceRequestData?.request_id) {
            setIsPollingActive(true);
        }
    }, [inferenceRequestIsSuccess, inferenceRequestData]);

    useEffect(() => {
        if (inferenceQueryIsSuccess && inferenceQueryData?.status !== "IN_PROGRESS") {
            setIsPollingActive(false);
        }
    }, [inferenceQueryIsSuccess, inferenceQueryData]);

    const startAudioInferenceToVocality = useCallback((data) => {
        const {
            title,
            processedText,
            voice,
            language,
            frequency,
            speed,
            colonDur,
            ellipsisDur,
            exclamationDur,
            interrogationDur,
            periodDur,
            semicolonDur,
            product,
            hardwareUsed,
        } = data;

        requestAudioInference({
            title: title,
            text: processedText,
            product_id: product.value,
            voice: voice.value,
            language: VOCALITY_VOICE_LANGUAGE_MAPPING[language.value],
            frequency: parseInt(frequency),
            speed: speed,
            semicolon_pause: semicolonDur,
            ellipsis_pause: ellipsisDur,
            double_point_pause: colonDur,
            question_mark_pause: interrogationDur,
            exclamation_mark_pause: exclamationDur,
            period_pause: periodDur,
            hardware_used: hardwareUsed,
        });
    });

    return {
        startAudioInferenceToVocality,
        audioURL: inferenceRequestIsSuccess && inferenceQueryData?.media_link,
        isPollingActive,
        resetInferenceRequest,

        error: inferenceQueryData?.status === "FAILED",
        inferenceRequestError,
        inferenceQueryError,
    };
};

export { useGenerateVocalityAudio };
