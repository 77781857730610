import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { AUDIO_FORMATS_OPTIONS } from "constants/murf";
import SoftSelect from "components/SoftSelect";

/**
 * Selector for the audio format.
 */
const AudioFormatSelector = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const { control } = useFormContext();

    const formatOptions = useMemo(() => {
        return AUDIO_FORMATS_OPTIONS.map((format) => ({
            value: format,
            label: format,
        }));
    }, []);

    const { field } = useController({
        name: "audioFormat",
        control,
        defaultValue: formatOptions[0],
    });

    return (
        <SoftBox mb={2}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("format_options_input_label")}
            </SoftTypography>
            <SoftSelect
                placeholder={t("format_options_input_placeholder")}
                value={field.value}
                onChange={field.onChange}
                options={formatOptions}
            />
        </SoftBox>
    );
};

export { AudioFormatSelector };
