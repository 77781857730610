import { api } from "app/baseQuery";

/**
 * Register API instance
 */
const authApi = api.injectEndpoints({
    tagTypes: ["Register"],
    endpoints: (build) => ({
        // Register user
        registerUser: build.mutation({
            query: (userInfo) => ({
                url: "/auth/register/user",
                method: "POST",
                body: userInfo,
            }),
            transformErrorResponse: (response) => {
                if (response.status === 400) {
                    return {
                        status: response.status,
                        message: response.data?.detail?.message.toLowerCase(),
                    };
                }
                return { status: response.status, message: "default_message" };
            },
        }),
        // Send invitation
        sendInvitation: build.mutation({
            query: (invitation) => ({
                url: "/auth/register/invitation",
                method: "POST",
                body: invitation,
            }),
            transformErrorResponse: (response) => {
                if (response.status === 400) {
                    return {
                        status: response.status,
                        message: response.data?.detail?.message.toLowerCase(),
                    };
                }
                return { status: response.status, message: "default_message" };
            },
        }),
        // Decode invitation token
        decodeInvitationToken: build.query({
            query: (token) => `/auth/register/decode-invitation?token=${token}`,
        }),
    }),
    overrideExisting: false,
});

export const { useDecodeInvitationTokenQuery, useRegisterUserMutation, useSendInvitationMutation } =
    authApi;
