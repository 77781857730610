import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SoftTypography from "components/SoftTypography";

import { BasicDashboardLayout } from "layouts/dashboards/Basic";
import { InferenceRequestsCharts } from "components/History/Charts/InferenceRequestCharts";
import { SpeakerInferenceRequestTable } from "components/History/Tables/SpeakerInferenceRequestTable";
import { InferenceCostHistoryChart } from "components/History/Charts/InferenceCostHistoryChart";

/**
 * Page to display the history of inference requests
 */
const SpeakerInferenceRequestHistoryPage = () => {
    const { t } = useTranslation("history");
    const user = useSelector((state) => state.auth.userProfile);

    return (
        <BasicDashboardLayout>
            {/* Title */}
            <SoftTypography variant="h4" fontWeight="medium">
                {t("inference_request_history_page_title")} - {user?.name} {user?.surname}
            </SoftTypography>
            {/* Charts */}
            <InferenceRequestsCharts
                title={t("charts.income_history_title")}
                SecondaryChart={InferenceCostHistoryChart}
            />
            {/* Table */}
            <SoftTypography variant="h5" fontWeight="medium" mt={4}>
                {t("inference_request_table_title")}
            </SoftTypography>
            <SpeakerInferenceRequestTable />
        </BasicDashboardLayout>
    );
};

export { SpeakerInferenceRequestHistoryPage };
