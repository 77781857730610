/**
 * Months of the year used by the translation function.
 */
// const MONTHS = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec"];

import { MONTHS } from "constants/time";

/**
 * Formats a number of seconds into a human-readable string.
 */
function prettifySeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.round(((seconds % 60) + Number.EPSILON) * 100) / 100;
    return `${minutes}min ${remainingSeconds}s`;
}

function getDatetimeString(datetime, locale = "es") {
    return new Date(datetime).toLocaleDateString(locale);
}

/**
 * Function to get the month name from a number.
 */
function getMonthName(month) {
    return MONTHS[month];
}

export { MONTHS, prettifySeconds, getMonthName, getDatetimeString };
