import { useEffect, useMemo } from "react";
import { useGetMurfVoicesQuery, useGetVoicesQuery } from "services/voice";

import {
    VOCALITY_VOICE_LANGUAGE_MAPPING,
    VOCALITY_ADDITIONAL_LANGUAGES,
} from "constants/languages";

/**
 * Hook to get the list of supported languages for the voice generation feature.
 */
const useVoices = () => {
    // DATA FETCHING
    const {
        data: vocalityVoicesData,
        error: vocalityError,
        isFetching: vocalityIsFetching,
        isSuccess: vocalityIsSuccess,
    } = useGetVoicesQuery();
    const {
        data: murfVoicesData,
        error: murfError,
        isFetching: murfIsFetching,
        isSuccess: murfIsSuccess,
    } = useGetMurfVoicesQuery();

    const isFetchingData = useMemo(
        () => vocalityIsFetching || murfIsFetching,
        [vocalityIsFetching, murfIsFetching]
    );

    useEffect(() => {
        if (vocalityError || murfError) {
            console.error(vocalityError, murfError);
        }
    }, [vocalityError, murfError]);

    // LANGUAGES
    const vocalityLanguages = useMemo(() => {
        if (!vocalityIsSuccess) return [];
        return Array.from(new Set(vocalityVoicesData.map((voice) => voice.language)));
    }, [vocalityVoicesData, vocalityIsSuccess]);

    const murfLanguages = useMemo(() => {
        if (!murfIsSuccess) return [];

        return Array.from(
            new Set(murfVoicesData.flatMap((voice) => Object.keys(voice.supportedLocales)))
        );
    }, [murfVoicesData, murfIsSuccess]);

    const availableLanguages = useMemo(() => {
        return VOCALITY_ADDITIONAL_LANGUAGES.concat(murfLanguages);
    }, [vocalityLanguages, murfLanguages]);

    // VOICES
    const getVocalityVoicesByLanguage = (language) => {
        if (!vocalityIsSuccess) return [];
        return vocalityVoicesData
            .filter((voice) => voice.language === VOCALITY_VOICE_LANGUAGE_MAPPING[language])
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    const getMurfVoicesByLanguage = (language) => {
        if (!murfIsSuccess) return [];
        return murfVoicesData
            .filter((voice) => Object.keys(voice.supportedLocales).includes(language))
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
    };

    return {
        isFetchingData,
        vocalityLanguages,
        murfLanguages,
        availableLanguages,
        getVocalityVoicesByLanguage,
        getMurfVoicesByLanguage,
    };
};

export { useVoices };
