import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DataTable from "components/Tables/DataTable";

import { UserInvitationDialog } from "./SpeakerInvitationDialog";

import { useGetVoicesQuery } from "services/voice";
import { getLocaleFromVocalityVoiceLanguage } from "constants/languages";
import { ROLES } from "constants/roles";

const OpenSpeakerInvitationDialogButton = ({ name, language }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <SoftBox ml={-1.325}>
                <SoftButton variant="gradient" color="secondary" onClick={() => setOpen(true)}>
                    {"crear usuario"}
                </SoftButton>
            </SoftBox>
            <UserInvitationDialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                options={{
                    role: ROLES.SPEAKER,
                    voice: name,
                    language: language,
                }}
            />
        </>
    );
};

OpenSpeakerInvitationDialogButton.propTypes = {
    name: PropTypes.string,
    language: PropTypes.string,
};

const SpeakersManagementTable = () => {
    // TODO: Change to own namespaces
    const { t } = useTranslation(["generate_voice_dashboard", "speakers_invitation", "locales"]);

    const { data: voices, isSuccess } = useGetVoicesQuery();

    const columns = useMemo(() => {
        return [
            {
                Header: t("table.headers.name", { ns: "speakers_invitation" }),
                accessor: "name",
            },
            {
                Header: t("table.headers.title", { ns: "speakers_invitation" }),
                accessor: "title",
            },
            {
                Header: t("table.headers.language", { ns: "speakers_invitation" }),
                accessor: "language",
            },
            {
                Header: t("table.headers.user", { ns: "speakers_invitation" }),
                accessor: "user",
            },
        ];
    }, [t]);

    const rows = useMemo(() => {
        if (!isSuccess) return [];

        return voices.map((voice) => {
            return {
                name: voice.name,
                title: voice.title,
                language: t(getLocaleFromVocalityVoiceLanguage(voice.language), {
                    ns: "locales",
                }),
                user: voice.user_id ? (
                    `${voice.user.name} ${voice.user.surname}`
                ) : (
                    <OpenSpeakerInvitationDialogButton
                        name={voice.name}
                        language={voice.language}
                    />
                ),
            };
        });
    }, [voices, isSuccess]);

    return <DataTable table={{ columns, rows }} />;
};

export { SpeakersManagementTable };
