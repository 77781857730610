import { api } from '../app/baseQuery'

const voiceApi = api.injectEndpoints({
    tagTypes: ['Voice'],
    endpoints: (build) => ({

        /**
         * Get voices - Devuelve lo voces disponibles
         */
        getVoices: build.query({
            query: () => 'db/voices/read',
        }),
        /**
         * Get murf voices - Return murf voices availables 
         */
        getMurfVoices: build.query({
            query: () => 'murf/list-voices'
        }),
        /**
         * Get user voice - Devuelve la voz del usuario si es que tiene una
         */
        getUserVoice: build.query({
            query: () => `db/voices/read-user-voice`,
        }),
    }),
    overrideExisting: false,
})

export const { 
    useGetVoicesQuery,
    useGetMurfVoicesQuery,
    useGetUserVoiceQuery,
} = voiceApi