import * as yup from "yup";

const userCreationDefaultValues = {
    name: "",
    surname: "",
    email: "",
    password: "",
    repeatPassword: "",
    termsOfService: false,
};

/**
 * Register form validation schema.
 */
const userCreationSchema = yup.object().shape({
    name: yup.string().required("required"),
    surname: yup.string().required("required"),
    email: yup.string().email("invalid_email").required("required"),
    password: yup
        .string()
        .required("required")
        .min(8, "password_min_length")
        .matches(/^(?=.*[0-9])/, "password_number")
        .matches(/^(?=.*[A-Z])/, "password_uppercase"),
    repeatPassword: yup
        .string()
        .required("required")
        .oneOf([yup.ref("password"), null], "password_mismatch"),
    termsOfService: yup.boolean().oneOf([true], "unaccepted_terms"),
});

export { userCreationSchema, userCreationDefaultValues };
