import { CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";

/**
 * Component that displays a loading circle.
 */
const LoadingCircle = () => {
    return (
        <SoftBox sx={{ flex: 1 }} display="flex" justifyContent="center">
            <CircularProgress />
        </SoftBox>
    );
};

export { LoadingCircle };
