import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import Icon from "@mui/material/Icon";
import { Button, CircularProgress, IconButton } from "@mui/material";

import SoftBox from "components/SoftBox";
import { useGenerateVocalityAudio } from "hooks/useGenerateVocalityAudio";

import { AudioDownloadButton } from "../AudioDownloadButton";

const AudioGenerationSubmitButton = ({ handleSubmit, onErrors }) => {
    const { t } = useTranslation("generate_voice_dashboard");
    const { reset } = useFormContext();

    const {
        startAudioInferenceToVocality,
        audioURL,
        isPollingActive,
        resetInferenceRequest,
        error,
        inferenceRequestError,
        inferenceQueryError,
    } = useGenerateVocalityAudio();

    const onSubmit = (data) => {
        startAudioInferenceToVocality(data);
    };

    const resetDashboard = () => {
        reset();
        resetInferenceRequest();
    };

    useEffect(() => {
        if (error || inferenceRequestError || inferenceQueryError) {
            Swal.fire(t("request.error_title"), t("request.error_message"), "error");
            resetDashboard();
        }
    }, [error, inferenceRequestError, inferenceQueryError]);

    return (
        <SoftBox display="flex" flexDirection="row" gap={2} alignItems="center">
            {isPollingActive ? (
                <SoftBox sx={{ flex: 1 }} display="flex" justifyContent="center">
                    <CircularProgress />
                </SoftBox>
            ) : audioURL ? (
                <SoftBox ml={2} display="flex" flexDirection="row" alignItems="center" flex={1}>
                    <AudioDownloadButton audioUrl={audioURL} mode="link" />
                    <IconButton
                        size="medium"
                        aria-label="close"
                        color="inherit"
                        onClick={resetDashboard}
                    >
                        <Icon fontSize="medium">refresh</Icon>
                    </IconButton>
                </SoftBox>
            ) : (
                <Button
                    // type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ color: "#ffffff", flex: 1 }}
                    onClick={handleSubmit(onSubmit, onErrors)}
                >
                    {t("generate_voice_button_text")}
                </Button>
            )}
        </SoftBox>
    );
};

AudioGenerationSubmitButton.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onErrors: PropTypes.func.isRequired,
};

export { AudioGenerationSubmitButton };
