import Swal from "sweetalert2";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSendInvitationMutation } from "services/auth";

/**
 * Hook to send a user invitation to join the app
 */
const useSendUserInvitation = ({ onSuccess }) => {
    const { t } = useTranslation("user_invitation_dialog");
    const [sendInvitation, { isSuccess, error, isLoading }] = useSendInvitationMutation();

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                title: t("success.title"),
                text: t("success.message"),
                icon: "success",
            }).then(() => {
                onSuccess();
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            Swal.fire({
                title: "Error",
                text: t(`error.${error.message}`),
                icon: "error",
            });
        }
    }, [error]);

    return {
        sendInvitation,
        isSuccess,
        error,
        isLoading,
    };
};

export { useSendUserInvitation };
