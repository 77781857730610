import slugify from "slugify";
import { useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";
import { useMemo } from "react";

import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";

import { useProducts } from "hooks/useProducts";

/**
 * ProductTypeSelector component for the VoiceGenerationDashboard page.
 */
const ProductTypeSelector = () => {
    const { t, i18n } = useTranslation("generate_voice_dashboard");
    const { control } = useFormContext();
    const { field } = useController({ name: "product", control });

    const { vocalityProducts } = useProducts();

    const availableProductOptions = useMemo(() => {
        if (!vocalityProducts) return [];
        return vocalityProducts.map((product) => ({
            value: product.id,
            label: t(`products.${slugify(product.name, { lower: true, replacement: "_" })}`),
        }));
    }, [vocalityProducts, i18n.language]);

    const handleProductChange = (selectedOption) => {
        field.onChange(selectedOption);
    };

    return (
        <SoftBox mb={2}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("product_type_options_input_label")}
            </SoftTypography>
            <SoftSelect
                placeholder={t("product_type_options_input_placeholder")}
                value={field.value}
                onChange={handleProductChange}
                options={availableProductOptions}
                key={`product-select-${field.value}`}
            />
        </SoftBox>
    );
};

export { ProductTypeSelector };
