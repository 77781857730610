import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Divider, Grid } from "@mui/material";

import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import { BasicDashboardLayout } from "layouts/dashboards/Basic";
import { UserSubscriptionInfo } from "components/management/UserSubscriptionInfo";
import { UserProfileInfoItem } from "components/management/UserProfileInfoItem";

const UpdateBillingInfoButton = () => {
    const { t } = useTranslation(["user_profile"]);
    const handleOnClick = () => {
        // eslint-disable-next-line no-undef
        window.location.href = process.env.REACT_APP_STRIPE_BILLING_URL;
    };

    return (
        <SoftButton variant="contained" color="primary" fullWidth onClick={handleOnClick}>
            {t("update_billing_info")}
        </SoftButton>
    );
};

const UserProfilePage = () => {
    const { t } = useTranslation(["user_profile"]);
    const user = useSelector((state) => state.auth.userProfile);

    return (
        <BasicDashboardLayout>
            <SoftTypography variant="h4" fontWeight="medium">
                {t("heading")}
            </SoftTypography>
            <Divider />
            <Grid container spacing={3}>
                {/* User profile data */}
                <Grid item xs={12} md={6}>
                    <SoftBox sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <UserProfileInfoItem
                            label={t("name")}
                            value={`${user.name} ${user.surname}`}
                        />
                        <UserProfileInfoItem label={t("email")} value={user.email} />
                    </SoftBox>
                </Grid>
                {/* Actions */}
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 2,
                    }}
                >
                    <UserSubscriptionInfo />
                    <UpdateBillingInfoButton />
                </Grid>
            </Grid>
        </BasicDashboardLayout>
    );
};

export { UserProfilePage };
