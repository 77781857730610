import { useTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";

import { SpeakersManagementTable } from "components/management/SpeakersManagementTable";
import { BasicDashboardLayout } from "layouts/dashboards/Basic";

function SpeakerInvitationPage() {
    const { t } = useTranslation("speakers_invitation");

    return (
        <BasicDashboardLayout>
            <SoftTypography variant="h4" fontWeight="medium">
                {t("heading")}
            </SoftTypography>
            <SpeakersManagementTable />
        </BasicDashboardLayout>
    );
}

export { SpeakerInvitationPage };
