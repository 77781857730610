import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import { Grid } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";

import { InferenceMinutesHistoryChart } from "components/History/Charts/InferenceMinutesHistoryChart";
import { useInferencesData } from "hooks/useInferences";

/**
 * Graph to filter inferences by year
 * @returns
 */
const InferenceRequestsCharts = ({ title, SecondaryChart }) => {
    const [graphYear, setGraphYear] = useState(new Date().getFullYear());
    const { inferences } = useInferencesData();

    const filteredInferences = inferences.filter(
        (inference) => new Date(inference.created_at).getFullYear() === graphYear
    );

    const yearOptions = useMemo(() => {
        const uniqueYears = Array.from(
            new Set(inferences.map((inference) => new Date(inference.created_at).getFullYear()))
        );
        return uniqueYears.map((year) => ({ value: year, label: year }));
    }, [inferences]);

    const onGraphYearChange = (yearOption) => {
        setGraphYear(yearOption.value);
    };

    return (
        <SoftBox>
            <SoftBox mb={2} display="flex" justifyContent="flex-end">
                <SoftSelect
                    value={{ value: graphYear, label: graphYear }}
                    onChange={onGraphYearChange}
                    options={yearOptions}
                />
            </SoftBox>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <InferenceMinutesHistoryChart inferences={filteredInferences} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SecondaryChart inferences={filteredInferences} title={title} />
                </Grid>
            </Grid>
        </SoftBox>
    );
};

InferenceRequestsCharts.propTypes = {
    title: PropTypes.string,
    SecondaryChart: PropTypes.func.isRequired,
};

export { InferenceRequestsCharts };
