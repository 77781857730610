import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { Button, CircularProgress, Icon, IconButton } from "@mui/material";

import SoftBox from "components/SoftBox";
import { useGenerateMurfAudio } from "hooks/useGenerateMurfAudio";

import { formatAudioFileName } from "utils/murf";
import { AudioDownloadButton } from "../AudioDownloadButton";
import { useEffect } from "react";
import Swal from "sweetalert2";

/**
 * Audio generation submit button for Murf
 */
const AudioGenerationSubmitButton = ({ handleSubmit, onErrors }) => {
    const { t } = useTranslation("generate_voice_dashboard");
    const { getValues, reset } = useFormContext();

    // Queries
    const {
        startAudioSynthesisToMurf,
        audioURL,
        isPollingActive,
        resetSynthesisRequest,
        error,
        synthesisRequestError,
        synthesisQueryError,
    } = useGenerateMurfAudio();

    const onSubmit = (data) => {
        startAudioSynthesisToMurf(data);
    };

    const resetDashboard = () => {
        reset();
        resetSynthesisRequest();
    };

    useEffect(() => {
        if (error || synthesisRequestError || synthesisQueryError) {
            Swal.fire(t("request.error_title"), t("request.error_message"), "error");
            resetDashboard();
        }
    }, [error, synthesisRequestError, synthesisQueryError]);

    return (
        <SoftBox display="flex" flexDirection="row" gap={2} alignItems="center">
            {isPollingActive ? (
                <SoftBox sx={{ flex: 1 }} display="flex" justifyContent="center">
                    <CircularProgress />
                </SoftBox>
            ) : audioURL ? (
                <SoftBox ml={2} display="flex" flexDirection="row" alignItems="center">
                    <AudioDownloadButton
                        audioUrl={audioURL}
                        filename={formatAudioFileName(
                            getValues("title"),
                            getValues("audioFormat").value
                        )}
                        mode="fetch"
                    />
                    <IconButton
                        size="medium"
                        aria-label="close"
                        color="inherit"
                        onClick={resetDashboard}
                    >
                        <Icon fontSize="medium">refresh</Icon>
                    </IconButton>
                </SoftBox>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ color: "#ffffff" }}
                    onClick={handleSubmit(onSubmit, onErrors)}
                >
                    {t("generate_voice_button_text")}
                </Button>
            )}
        </SoftBox>
    );
};

AudioGenerationSubmitButton.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onErrors: PropTypes.func.isRequired,
};

export { AudioGenerationSubmitButton };
