import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RequestStatusLabel } from "components/History/RequestStatusLabel";
import { DownloadAudioIcon } from "components/History/DownloadAudioIcon";

import { MONTHS, prettifySeconds } from "utils/time";
import { formatAudioFileName } from "utils/murf";

import { getLocaleFromVocalityVoiceLanguage } from "constants/languages";

import { useInferencesData } from "hooks/useInferences";
import { useProducts } from "hooks/useProducts";

/**
 * Hook to get inference request table data
 * @returns
 */
const useInferenceRequestTable = ({ columns }) => {
    const { t, i18n } = useTranslation(["locales", "months", "inferences"]);

    // Queries
    const { vocalityInferences, murfSynthesisRequests } = useInferencesData();
    const {
        products,
        getProductNameById,
        getProductCostById,
        getProductDefaultPriceByIdInMinutes,
        getProductCostByIdInMinutes,
        calculateTotalPrice,
        calculateTotalCost,
    } = useProducts();

    // Column factories
    const getExpirationDatetime = useCallback(
        (datetime) => {
            if (!datetime) return "---";
            return new Date(datetime).toLocaleDateString(i18n.language);
        },
        [i18n]
    );

    const getMonth = useCallback(
        (date) => {
            return t(MONTHS[new Date(date).getMonth()], {
                ns: "months",
            });
        },
        [t]
    );

    const getProductPrice = useCallback(
        (productId) => {
            return `${getProductDefaultPriceByIdInMinutes(productId)} €/min`;
        },
        [getProductDefaultPriceByIdInMinutes]
    );

    const getTotalPrice = useCallback(
        (productId, duration) => {
            return `${calculateTotalPrice(productId, duration)} €`;
        },
        [calculateTotalPrice]
    );

    const getProductCost = useCallback((productId) => {
        return `${getProductCostByIdInMinutes(productId)} €/min`;
    });

    const getTotalCost = useCallback((productId, duration) => {
        return `${calculateTotalCost(productId, duration)} €`;
    });

    const getDownloadAudioIconFromMurf = useCallback(({ audio_url, status, title, format }) => {
        return (
            <DownloadAudioIcon
                audioUrl={audio_url}
                status={status}
                filename={formatAudioFileName(title, format)}
                mode="fetch"
            />
        );
    }, []);

    const getDownloadAudioIconFromVocality = useCallback(({ media_link, status }) => {
        return <DownloadAudioIcon audioUrl={media_link} status={status} mode="link" />;
    }, []);

    const commonRowFactories = useMemo(
        () => ({
            "id": (inference) => inference.id,
            "title": (inference) => inference.title,
            "user": (inference) => `${inference.user.name} ${inference.user.surname}`,
            "creation_date": (inference) =>
                new Date(inference.created_at).toLocaleDateString(i18n.language),
            "creation_year": (inference) => new Date(inference.created_at).getFullYear(),
            "creation_month": (inference) => getMonth(inference.created_at),
            "creation_date_full": (inference) => new Date(inference.created_at),
            "status": (inference) => <RequestStatusLabel status={inference.status} />,
            "expiration_date": (inference) => getExpirationDatetime(inference.expiration_datetime),
            "duration": (inference) => prettifySeconds(inference.audio_length),
            "cost": (inference) => getProductCostById(inference.product_id),
            "price": (inference) => getProductPrice(inference.product_id),
            "cost": (inference) => getProductCost(inference.product_id),
            "total_price": (inference) =>
                getTotalPrice(inference.product_id, inference.audio_length),
            "total_cost": (inference) => getTotalCost(inference.product_id, inference.audio_length),
        }),
        [i18n.language, getProductCostById, getTotalPrice, getProductPrice]
    );

    const murfRowFactories = useMemo(
        () => ({
            ...commonRowFactories,
            "product": (inference) => inference.style,
            "language": (inference) => t(inference.multi_native_locale),
            "speaker": (inference) => inference.voice_id,
            "download": (inference) => getDownloadAudioIconFromMurf(inference),
        }),
        [commonRowFactories]
    );

    const vocalityRowFactories = useMemo(
        () => ({
            ...commonRowFactories,
            "product": (inference) => getProductNameById(inference.product_id),
            "language": (inference) => t(getLocaleFromVocalityVoiceLanguage(inference.idioma)),
            "speaker": (inference) => inference.voz,
            "download": (inference) => getDownloadAudioIconFromVocality(inference),
        }),
        [commonRowFactories, getProductNameById]
    );

    /**
     * Function to create vocality table row data
     */
    const createMurfTableRowData = useCallback(
        (murfSynthesisRequest) => {
            return columns.reduce(
                (acc, column) => {
                    return {
                        ...acc,
                        [column]: murfRowFactories[column](murfSynthesisRequest),
                    };
                },
                {
                    created_at: murfSynthesisRequest.created_at,
                }
            );
        },
        [columns, murfRowFactories]
    );

    const createVocalityTableRowData = useCallback(
        (inferenceRequest) => {
            return columns.reduce(
                (acc, column) => {
                    return {
                        ...acc,
                        [column]: vocalityRowFactories[column](inferenceRequest),
                    };
                },
                {
                    created_at: inferenceRequest.created_at,
                }
            );
        },
        [columns, vocalityRowFactories]
    );

    const dataTableRows = useMemo(() => {
        if (!vocalityInferences || !murfSynthesisRequests || !products) return [];
        return [
            ...vocalityInferences.map(createVocalityTableRowData),
            ...murfSynthesisRequests.map(createMurfTableRowData),
        ].sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });
    }, [
        vocalityInferences,
        murfSynthesisRequests,
        products,
        createVocalityTableRowData,
        createMurfTableRowData,
        i18n.language,
    ]);

    return {
        dataTableRows,
    };
};

export { useInferenceRequestTable };
