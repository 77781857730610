import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { useGetSubscriptionQuery } from "services/stripe";

import { LoadingCircle } from "components/Loading";
import { useTranslation } from "react-i18next";
import { getDatetimeString } from "utils/time";

import { UserProfileInfoItem } from "./UserProfileInfoItem";
import { ErrorMessage } from "components/ErrorMessage";

/**
 * Information about the user's subscription
 */
const UserSubscriptionInfo = () => {
    const { t, i18n } = useTranslation(["user_profile"]);

    const { data: subscription, isFetching, error } = useGetSubscriptionQuery();

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (isFetching) {
        return <LoadingCircle />;
    }

    return (
        <SoftBox>
            <SoftTypography variant="h5">{t("subscription.heading")}</SoftTypography>
            {/* Subscription start date */}
            <UserProfileInfoItem
                label={t("start_date_label")}
                value={getDatetimeString(subscription.start_date * 1000, i18n.language)}
            />
            {/* Subscription status and trial end date */}
            <SoftBox
                sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            >
                <UserProfileInfoItem
                    sx={{ flex: 1 }}
                    label={t("subscription.status_label")}
                    value={t(`subscription.status.${subscription.status}`)}
                />
                {subscription.status === "trialing" && (
                    <UserProfileInfoItem
                        sx={{ flex: 1 }}
                        label={t("trial_end_label")}
                        value={getDatetimeString(subscription.trial_end * 1000, i18n.language)}
                    />
                )}
            </SoftBox>
            {/* Subscription collection method */}
            <UserProfileInfoItem
                label={t("collection_method.collection_method_label")}
                value={t(`collection_method.${subscription.collection_method}`)}
            />

            {/* Subscription start and end dates */}
            <SoftBox
                sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            >
                <UserProfileInfoItem
                    sx={{ flex: 1 }}
                    label={t("current_period_start_label")}
                    value={getDatetimeString(
                        subscription?.current_period_start * 1000,
                        i18n.language
                    )}
                />
                <UserProfileInfoItem
                    sx={{ flex: 1 }}
                    label={t("current_period_end_label")}
                    value={getDatetimeString(
                        subscription?.current_period_end * 1000,
                        i18n.language
                    )}
                />
            </SoftBox>
        </SoftBox>
    );
};

export { UserSubscriptionInfo };
