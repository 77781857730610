import PropTypes from "prop-types";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

const TextInput = ({ name, label, ...rest }) => {
    const { t } = useTranslation("form");

    const {
        register,
        formState: { errors },
    } = useFormContext();

    const error = useMemo(() => errors[name], [errors[name]]);

    return (
        <SoftBox>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {label}
            </SoftTypography>
            <SoftBox>
                <SoftInput {...register(name)} {...rest} error={error != undefined} />
            </SoftBox>
            {error && (
                <SoftTypography variant="caption" color="error">
                    {t(error?.message)}
                </SoftTypography>
            )}
        </SoftBox>
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export { TextInput };
