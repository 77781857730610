/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SidenavCollapse
import {
    collapseItem,
    collapseIconBox,
    collapseIcon,
    collapseText,
    collapseArrow,
} from "examples/Sidenav/styles/sidenavCollapse";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";
import { useTranslation } from "react-i18next";

function SidenavCollapse({
    icon,
    name,
    children,
    active,
    notifications,
    noCollapse,
    open,
    ...rest
}) {
    const [controller] = useSoftUIController();
    const { miniSidenav, transparentSidenav, sidenavColor } = controller;

    const { t } = useTranslation("sidenav");

    return (
        <>
            <ListItem component="li">
                <SoftBox
                    {...rest}
                    sx={(theme) => collapseItem(theme, { active, transparentSidenav })}
                >
                    <Badge badgeContent={notifications ? notifications : 0} color="secondary">
                        <ListItemIcon
                            sx={(theme) =>
                                collapseIconBox(theme, { active, transparentSidenav, sidenavColor })
                            }
                        >
                            {typeof icon === "string" ? (
                                <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                            ) : (
                                icon
                            )}
                        </ListItemIcon>
                    </Badge>
                    <ListItemText
                        primary={t(name)}
                        sx={(theme) =>
                            collapseText(theme, { miniSidenav, transparentSidenav, active })
                        }
                    />

                    <Icon
                        sx={(theme) =>
                            collapseArrow(theme, {
                                noCollapse,
                                transparentSidenav,
                                miniSidenav,
                                open,
                            })
                        }
                    >
                        expand_less
                    </Icon>
                </SoftBox>
            </ListItem>
            {children && (
                <Collapse in={open} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
    color: "info",
    active: false,
    noCollapse: false,
    children: false,
    open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    active: PropTypes.bool,
    notifications: PropTypes.number,
    noCollapse: PropTypes.bool,
    open: PropTypes.bool,
};

export default SidenavCollapse;
