import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import DataTable from "components/Tables/DataTable";
import { useInferenceRequestTable } from "hooks/useInferenceTable";

/**
 * Inference request table component
 */
const ClientInferenceRequestTable = () => {
    const { t } = useTranslation("inferences");
    const columns = useMemo(
        () => ({
            "title": { Header: t("table.headers.title", { ns: "inferences" }), accessor: "title" },
            "user": { Header: t("table.headers.user", { ns: "inferences" }), accessor: "user" },
            "product": {
                Header: t("table.headers.product_type", { ns: "inferences" }),
                accessor: "product",
            },
            "language": {
                Header: t("table.headers.language", { ns: "inferences" }),
                accessor: "language",
            },
            "speaker": {
                Header: t("table.headers.speaker", { ns: "inferences" }),
                accessor: "speaker",
            },
            "creation_date": {
                Header: t("table.headers.creation_date", { ns: "inferences" }),
                accessor: "creation_date",
            },
            "creation_year": {
                Header: t("table.headers.creation_year", { ns: "inferences" }),
                accessor: "creation_year",
            },
            "creation_month": {
                Header: t("table.headers.creation_month", { ns: "inferences" }),
                accessor: "creation_month",
            },
            "status": {
                Header: t("table.headers.status", { ns: "inferences" }),
                accessor: "status",
            },
            "expiration_date": {
                Header: t("table.headers.expiration_date", { ns: "inferences" }),
                accessor: "expiration_date",
            },
            "duration": {
                Header: t("table.headers.duration", { ns: "inferences" }),
                accessor: "duration",
            },
            "download": {
                Header: t("table.headers.download", { ns: "inferences" }),
                accessor: "download",
            },
        }),
        [t]
    );

    const { dataTableRows } = useInferenceRequestTable({
        columns: Object.keys(columns),
    });

    return (
        <DataTable
            canSearch
            table={{
                columns: Object.values(columns),
                rows: dataTableRows,
            }}
        />
    );
};

ClientInferenceRequestTable.propTypes = {
    dataTableRows: PropTypes.array.isRequired,
};

export { ClientInferenceRequestTable };
