/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

const StyledSlider = styled(Slider)({
    color: '#279989',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      color: '#fff',
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#279989',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

  function VocalitySlider({ min, max, step, marks, title, value, onChange }) {
    
    const generateMarks = () => {
      if (!marks) return [];
      const stepInterval = (max - min) / 4;
      return Array.from({ length: 5 }, (_, i) => ({
        value: min + i * stepInterval,
        label: (min + i * stepInterval).toFixed(2)
      }));
    };
  
    return (
      <SoftBox ml={2} mr={2}>
          {title && 
          <SoftTypography variant="h6" fontWeight="regular" textTransform="capitalize">
              {title}
          </SoftTypography>}
          <StyledSlider
          value={value}
          onChange={onChange}
          valueLabelDisplay="auto"
          marks={generateMarks()}
          step={step}
          min={min}
          max={max}
          />
      </SoftBox>
    );
  }
  
  VocalitySlider.propTypes = {
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      step: PropTypes.number.isRequired,
      marks: PropTypes.bool,
      title: PropTypes.string,
      value: PropTypes.number,
      onChange: PropTypes.func,
  };

export default VocalitySlider;
