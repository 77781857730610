/**
 * Supported languages in the application
 */
const LANGUAGES = {
    SPANISH: "es",
    ENGLISH: "en",
};

/**
 * Mapping between the language code and the corresponding voice language in Vocality
 */
const VOCALITY_VOICE_LANGUAGE_MAPPING = {
    "es-NEU": "español_neutro",
    "es-ES": "español",
    "en-UK": "inglés",
    "de-DE": "alemán",
    "eu-ES": "euskera",
    "nl-NL": "holandés",
    "pt-PT": "portugués",
    "ru-RU": "ruso",
    "sl-SI": "esloveno",
};

const getLocaleFromVocalityVoiceLanguage = (voiceLanguage) => {
    return Object.keys(VOCALITY_VOICE_LANGUAGE_MAPPING).find(
        (key) => VOCALITY_VOICE_LANGUAGE_MAPPING[key] === voiceLanguage
    );
};

/**
 * Additional languages supported by Vocality
 */
const VOCALITY_ADDITIONAL_LANGUAGES = ["es-NEU", "eu-ES", "pt-PT", "ru-RU", "sl-SI"];

export {
    LANGUAGES,
    VOCALITY_VOICE_LANGUAGE_MAPPING,
    VOCALITY_ADDITIONAL_LANGUAGES,
    getLocaleFromVocalityVoiceLanguage,
};
