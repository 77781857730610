import SoftBox from "components/SoftBox";

import { ModelVersionRadioButtons } from "./ModelVersionRadioButtons";
import { VoiceStyleSelector } from "./VoiceStyleSelector";
import { AudioFormatSelector } from "./AudioFormatSelector";
import { ChannelTypeRadioButtons } from "./ChannelTypeRadioButtons";
import { FrequencyRadioButtons } from "./FrecuencyRadioButtons";
import { AudioSpeedSlider } from "./AudioSpeedSlider";
import { AudioPitchSlider } from "./AudioPitchSlider";
import { PronunciationDictionary } from "./PronunciationDictionary";

/**
 * Additional inputs for the Murf voice generation.
 * @returns
 */
const MurfVoiceGenerationInputs = () => {
    return (
        <SoftBox>
            <ModelVersionRadioButtons />
            <VoiceStyleSelector />
            <AudioFormatSelector />
            <ChannelTypeRadioButtons />
            <FrequencyRadioButtons />
            <AudioSpeedSlider />
            <AudioPitchSlider />
            <PronunciationDictionary />
        </SoftBox>
    );
};

export { MurfVoiceGenerationInputs };
