import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * Protected API instance
 */
const api = createApi({
    // Reducer path
    reducerPath: "api",

    // Base URL
    baseQuery: fetchBaseQuery({
        // eslint-disable-next-line no-undef
        baseUrl: process.env.REACT_APP_API_URL,

        // Authorization header
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userToken;

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),

    // Tag types
    tagTypes: ["Inference", "Synthesis"],

    // Endpoints
    endpoints: () => ({}),
});

export { api };
