import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Locales
import es from "./es.json";
import en from "./en.json";

import { LANGUAGES } from "constants/languages";

/**
 * Resources for the supported languages
 */
const langResources = { [LANGUAGES.SPANISH]: es, [LANGUAGES.ENGLISH]: en };

i18n
    // Pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        fallbackLng: [LANGUAGES.SPANISH, LANGUAGES.ENGLISH],
        supportedLngs: [LANGUAGES.SPANISH, LANGUAGES.ENGLISH],
        lng: LANGUAGES.SPANISH,
        resources: langResources,
        compatibilityJSON: "v3",
    });

export default i18n;
