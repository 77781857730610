/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Custom styles for DashboardNavbar
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarDesktopMenu,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar, setMiniSidenav } from "context";

import { useLogin } from "hooks/useLogin";

import { LanguageSelector } from "components/LanguageSelector";
import { useTranslation } from "react-i18next";

function DashboardNavbar({ absolute, light, isMini }) {
    const { t } = useTranslation("sidenav");
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useSoftUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
    const route = useLocation().pathname.split("/").slice(1);

    // Hook to handle the logout
    const { logoutUser } = useLogin();

    //Title object
    const titles = {
        default: "Generación de voz",
        history: "Histórico",
    };

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleLogout = () => logoutUser();

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <SoftBox
                    color="inherit"
                    mb={{ xs: 1, md: 0 }}
                    sx={(theme) => navbarRow(theme, { isMini })}
                >
                    <SoftBox mr={{ xs: 0, xl: 6 }}>
                        <SoftTypography
                            fontWeight="bold"
                            textTransform="capitalize"
                            variant="h6"
                            color={light ? "white" : "dark"}
                            noWrap
                        >
                            {titles[route[route.length - 1].replace("-", " ")]}
                        </SoftTypography>
                    </SoftBox>
                    <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
                        {miniSidenav ? "menu_open" : "menu"}
                    </Icon>
                </SoftBox>
                {isMini ? null : (
                    <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
                        <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            color={light ? "white" : "inherit"}
                        >
                            <IconButton
                                size="small"
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon className={light ? "text-white" : "text-dark"}>
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            <LanguageSelector mr={5} />
                            <IconButton sx={navbarIconButton} size="medium" onClick={handleLogout}>
                                <Icon
                                    sx={({ palette: { dark, white } }) => ({
                                        color: light ? white.main : dark.main,
                                    })}
                                >
                                    logout
                                </Icon>
                                <SoftTypography
                                    variant="button"
                                    fontWeight="medium"
                                    color={light ? "white" : "dark"}
                                >
                                    {t("logout")}
                                </SoftTypography>
                            </IconButton>
                        </SoftBox>
                    </SoftBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
