import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

/**
 * Hook to handle exports to Excel
 */
const useExcelExport = () => {
    const createTimestampedFileName = (filename) => {
        return filename + new Date().toISOString().replace(/:/g, "-");
    };

    /**
     * Create a workbook with multiple sheets
     * @param {Object} sheets - Object with keys wsName and data (array of objects)
     * @returns {Object} - Workbook
     */
    const createWorkbook = (sheets) => {
        const wb = XLSX.utils.book_new();
        Object.entries(sheets).forEach(([sheetName, sheetData]) => {
            const ws = XLSX.utils.json_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });
        return wb;
    };

    const saveWorkbook = (workbook, fileName) => {
        const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const wbdata = new Blob([wbout], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(wbdata, createTimestampedFileName(fileName) + ".xlsx");
    };

    const downloadWorkbook = ({ data, fileName }) => {
        const wb = createWorkbook(data);
        saveWorkbook(wb, fileName);
    };

    return {
        downloadWorkbook,
    };
};

export { useExcelExport };
