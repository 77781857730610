/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Separator from "layouts/authentication/components/Separator";

// Images
import brand from "assets/images/logos/Logo-vocality-RGB_color-tagline.svg";

// Soft UI Dashboard PRO React example components
import Loading from "examples/Animations/Loading";

// Roles
import { ROLES } from "constants/roles";

// Authentication hook
import { useLogin } from "hooks/useLogin";
import { statusEnum } from "features/auth/authSlice";

import { LanguageSelector } from "components/LanguageSelector";
import { useTranslation } from "react-i18next";

const emptySample = {
    username: "",
    password: "",
};

function Basic() {
    const [form, setForm] = useState(emptySample);

    // Translation
    const { t } = useTranslation("sign_in");

    // Navigation
    const navigate = useNavigate();

    // Hook to handle the login
    const { loginUser } = useLogin();
    const { userProfile, status, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (status === statusEnum.authenticated) {
            navigate(localStorage.getItem("last_visited") || "/generate");
        }
    }, [status]);

    // Handlers

    const handleEmailChange = (e) =>
        setForm({ ...form, username: e.target.value ? e.target.value : null });
    const handlePassChange = (e) =>
        setForm({ ...form, password: e.target.value ? e.target.value : null });

    const handleSubmit = () => {
        loginUser(form);
    };
    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            loginUser(form);
        }
    };

    return (
        <BasicLayout image={brand}>
            <Card>
                <SoftBox p={3} mb={1} textAlign="center" position="relative">
                    <SoftTypography variant="h5" fontWeight="medium">
                        {t("title")}
                    </SoftTypography>
                    <SoftBox position="absolute" top="25%" right="16px">
                        <LanguageSelector />
                    </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                    <SoftBox component="form" role="form">
                        <SoftBox mb={2}>
                            <SoftInput
                                type="email"
                                placeholder={t("email_placeholder")}
                                value={form.username}
                                onChange={handleEmailChange}
                                onKeyDown={onKeyDownHandler}
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftInput
                                type="password"
                                placeholder={t("password_placeholder")}
                                value={form.password}
                                onChange={handlePassChange}
                                onKeyDown={onKeyDownHandler}
                            />
                        </SoftBox>
                        {error && (
                            <SoftTypography variant="caption" fontWeight="regular" color="error">
                                {error.code == "ERR_BAD_REQUEST"
                                    ? t("error_bad_request")
                                    : error.message}
                            </SoftTypography>
                        )}
                        {status === statusEnum.nonAuthenticated && (
                            <div>
                                <SoftBox mt={4} mb={1}>
                                    <SoftButton
                                        onClick={handleSubmit}
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                    >
                                        {t("sign_in_button")}
                                    </SoftButton>
                                </SoftBox>
                                <Separator />
                                <SoftBox mt={1} mb={3}>
                                    <SoftButton
                                        component={Link}
                                        to="/authentication/register"
                                        variant="gradient"
                                        color="secondary"
                                        fullWidth
                                    >
                                        {t("register_button")}&nbsp; <Icon>arrow_forward_ios</Icon>
                                    </SoftButton>
                                </SoftBox>
                            </div>
                        )}
                        {status === statusEnum.checkingAuthentication && (
                            <div>
                                <SoftBox mt={5} mb={5}>
                                    <Loading />
                                </SoftBox>
                            </div>
                        )}
                    </SoftBox>
                </SoftBox>
            </Card>
        </BasicLayout>
    );
}

export default Basic;
