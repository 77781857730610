import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DefaultLineChart from "examples/Charts/DefaultLineChart";
import { roundToTwoDecimals } from "utils/numbers";
import { useProducts } from "hooks/useProducts";
import { MONTHS } from "constants/time";
import { useMemo } from "react";

const InferenceCostHistoryChart = ({ inferences, title }) => {
    const { t } = useTranslation(["history", "months"]);

    const { calculateTotalCost } = useProducts();

    const data = useMemo(() => {
        return Array.from({ length: 12 }, (_, month) => month).map((month) => {
            const monthData = inferences.filter(
                (inference) => new Date(inference.created_at).getMonth() === month
            );
            const monthlyAcc = monthData.reduce(
                (acc, inference) =>
                    acc +
                    roundToTwoDecimals(
                        calculateTotalCost(inference.product_id, inference.audio_length) || 0
                    ),
                0
            );
            return monthlyAcc > 0 ? monthlyAcc : null;
        });
    }, [inferences, calculateTotalCost]);

    const chartData = useMemo(
        () => ({
            labels: MONTHS.map((month) => t(month, { ns: "months" })),
            datasets: [
                {
                    label: "€",
                    color: "info",
                    data: data,
                },
            ],
        }),
        [inferences]
    );

    return <DefaultLineChart title={title} chart={chartData} />;
};

InferenceCostHistoryChart.propTypes = {
    inferences: PropTypes.array.isRequired,
    title: PropTypes.string,
};

export { InferenceCostHistoryChart };
