import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";

import { VocalityAudioTextEditor } from "./vocality/VocalityTextEditor";
import { MurfAudioTextEditor } from "./murf/MurfTextEditor";
import { SUPPORTED_PLATFORMS } from "constants/platforms";

const audioTextEditorPlatformComponents = {
    [SUPPORTED_PLATFORMS.VOCALITY]: VocalityAudioTextEditor,
    [SUPPORTED_PLATFORMS.MURF]: MurfAudioTextEditor,
};

/**
 * AudioTextEditor component for the VoiceGenerationDashboard page.
 */
const AudioTextEditor = () => {
    const { watch } = useFormContext();

    const voice = watch("voice");

    const AudioTextEditorComponent = useMemo(() => {
        return (
            audioTextEditorPlatformComponents[voice?.metadata?.platform] || VocalityAudioTextEditor
        );
    }, [voice]);

    return (
        <SoftBox>
            <AudioTextEditorComponent />
        </SoftBox>
    );
};

export { AudioTextEditor };
