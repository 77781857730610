import PropTypes from "prop-types";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";

import DescriptionIcon from "@mui/icons-material/Description";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

//XLSX
import { parseExcelFileToJSON } from "utils/excel";
import SoftTypography from "components/SoftTypography";

/**
 * Dropzone component for processing files
 */
function SoftDropzone({ name, options, handler }) {
    const { t } = useTranslation("inputs");

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            parseExcelFileToJSON(file, handler);
        } else if (file.type === "text/plain") {
            file.text().then(function (response) {
                handler({ file, content: response, error: null });
            });
        } else {
            console.error("Error: file type not supported:", file.type);
        }
    }, []);

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ onDrop });

    //---------------------------------------------------------------------------------

    return (
        <>
            <SoftDropzoneRoot
                component="div"
                className="form-control dropzone"
                justifyContent="center"
                sx={{ minHeight: "10rem" }}
                {...getRootProps({ ...options?.rootProps })}
            >
                <SoftBox className="fallback" component="div">
                    <SoftBox
                        component="input"
                        name={name}
                        {...getInputProps({ ...options?.inputProps })}
                    />
                    {acceptedFiles.length > 0 ? (
                        <SoftBox
                            component="div"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <DescriptionIcon color="primary" fontSize="large" />
                            <SoftTypography variant="button" fontWeight="medium">
                                {acceptedFiles[0].name}
                            </SoftTypography>
                        </SoftBox>
                    ) : (
                        <SoftTypography variant="button" fontWeight="medium">
                            {t("dropzone.title")}
                        </SoftTypography>
                    )}
                </SoftBox>
            </SoftDropzoneRoot>
            {acceptedFiles.length > 0 && (
                <SoftTypography variant="caption" fontWeight="medium">
                    {t("dropzone.file_uploaded")} <ArrowForwardIcon /> {acceptedFiles[0].name}
                </SoftTypography>
            )}
        </>
    );
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
    handler: PropTypes.func,
};

export default SoftDropzone;
