import SoftBox from "components/SoftBox";
import StepperCardLayout from "layouts/authentication/components/StepperCardLayout";
import { RegisterForm } from "components/auth/RegisterForm/index.js";

/**
 * User Registration Page
 */
const UserRegistrationPage = () => {
    return (
        <StepperCardLayout>
            <SoftBox sx={{ width: "100%", mt: 2, display: "flex", justifyContent: "center" }}>
                <SoftBox sx={{ width: "65%" }}>
                    <RegisterForm />
                </SoftBox>
            </SoftBox>
        </StepperCardLayout>
    );
};

export { UserRegistrationPage };
