import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { LegalText } from "components/Legal";

import { useTranslation } from "react-i18next";
import { SoftCheckBox } from "components/Forms/CheckBox";

function TermsOfService() {
    const { t } = useTranslation("terms_of_service");

    return (
        <SoftBox mt={2}>
            {/* Header */}
            <SoftTypography variant="h4" fontWeight="bold">
                {t("title")}
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text">
                {t("subtitle")}
            </SoftTypography>

            {/* Legal text */}
            <SoftBox mt={1.625}>
                <LegalText />
            </SoftBox>

            {/* Checkbox */}
            <SoftBox sx={{ pl: 1.5 }}>
                <SoftCheckBox name="termsOfService" label={t("checkbox_text")} />
            </SoftBox>
        </SoftBox>
    );
}

export { TermsOfService };
