import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import SoftBox from "components/SoftBox";

import { VocalityVoiceGenerationInputs } from "./vocality/VocalityVoiceGenerationInputs";
import { MurfVoiceGenerationInputs } from "./murf/MurfVoiceGenerationInputs";

import { SUPPORTED_PLATFORMS } from "constants/platforms";

const voiceGenerationInputsPlatformComponents = {
    [SUPPORTED_PLATFORMS.VOCALITY]: VocalityVoiceGenerationInputs,
    [SUPPORTED_PLATFORMS.MURF]: MurfVoiceGenerationInputs,
};

const VoiceGenerationInputs = () => {
    const { watch } = useFormContext();

    const voice = watch("voice");

    const VoiceGenerationinputsComponent = useMemo(() => {
        return (
            voiceGenerationInputsPlatformComponents[voice?.metadata?.platform] ||
            VocalityVoiceGenerationInputs
        );
    }, [voice]);

    return (
        <SoftBox mb={2}>
            <VoiceGenerationinputsComponent />
        </SoftBox>
    );
};

export { VoiceGenerationInputs };
