import Swal from "sweetalert2";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid } from "@mui/material";

import SoftBox from "components/SoftBox";

import { VoiceSelector } from "./VoiceSelector";
import { TitleInput } from "./TitleInput";
import { AudioTextEditor } from "./AudioTextEditor";
import { VoiceGenerationInputs } from "./VoiceGenerationInputs";
import { audioGenerationSchema } from "validations/generateAudio";
import { AudioGenerationSubmitButton } from "./AudioGenerationSubmitButton";

const defaultValues = {
    // Common inputs
    title: undefined,
    language: undefined,
    voice: undefined,
    speed: undefined,
    frequency: undefined,
    processedText: undefined,
    // Vocality specific inputs
    colonDur: undefined,
    ellipsisDur: undefined,
    exclamationDur: undefined,
    interrogationDur: undefined,
    periodDur: undefined,
    semicolonDur: undefined,
    product: undefined,
    hardwareUsed: undefined,
    textProcessingDictionary: undefined,
    // Murf specific inputs
    pronunciationDictionary: undefined,
    audioFormat: undefined,
    channelType: undefined,
    modelVersion: undefined,
    pitch: undefined,
    style: undefined,
};

/**
 * Form for generating voice from text
 */
const VoiceGenerationForm = () => {
    const { t } = useTranslation("generate_voice_dashboard");

    const methods = useForm({
        resolver: yupResolver(audioGenerationSchema),
        defaultValues,
    });

    const onErrors = (errors) => {
        if (Object.keys(errors).length > 0) {
            return Swal.fire(
                t("request.validation_error_title"),
                t("request.validation_error_message"),
                "warning"
            );
        }
    };

    return (
        <FormProvider {...methods}>
            <form>
                <Grid container spacing={3}>
                    {/* Left panel */}
                    <Grid item xs={12} lg={5}>
                        {/* Common inputs */}
                        <SoftBox display="flex" flexDirection="column" gap={2}>
                            <TitleInput />
                            <VoiceSelector />
                        </SoftBox>
                        {/* Specific inputs */}
                        <VoiceGenerationInputs />
                    </Grid>
                    {/* Right panel */}
                    <Grid item xs={12} lg={7}>
                        <SoftBox>
                            <AudioTextEditor />
                            <AudioGenerationSubmitButton
                                handleSubmit={methods.handleSubmit}
                                onErrors={onErrors}
                            />
                        </SoftBox>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export { VoiceGenerationForm };
